import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>Kwa nini na Tofauti Yetu Ni Ipi?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              Faida ya HipHop.Dating ikilinganishwa na majukwaa mengine mengi ya
              kutongoza ni kwamba watumiaji wote tayari wana shauku moja kuu
              katika maisha ambayo ni “Upendo wa Hip Hop na utamaduni wake”.
              <br />
              <br />
              Majukwaa mengine yanaruhusu watumiaji kuchuja mechi zinazowezekana
              wanazoona, kutoa nje makabila, umri, au maeneo yasiyopendelewa.
              Unachuja, unaonyesha “una nia/huna,” unarudia, nk. Baadhi ya
              programu zinatumia algorithms kuchagua nani wa kuonyesha kwako, na
              lini.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              Ukosefu wa maslahi ya msingi ya pamoja, shauku na upendo miongoni
              mwa watumiaji inafanya iwe vigumu kwa programu nyingi za kutongoza
              kusaidia kwa uhalisia watumiaji kupata wenzi wa roho zao.
              <br />
              <br />
              Katika <b>HipHop.Dating</b>, watumiaji tayari wanafahamu kwamba
              wao wanashiriki maslahi, shauku na upendo wa Hip Hop na utamaduni
              wake (maisha, muziki, mitindo, dansi, ushairi, sanaa, nk).
              Wanaweza tu kuchat, kutongoza, kushiriki na kugunduliana kwa
              asili.
              <br />
              <br />
              Zaidi ya hayo, Wachumbaji wa <b>Hip Hop Daters</b> (watumiaji)
              wanaweza kutengeneza pesa kwa kuunda na kupost maudhui wakati
              wanafurahia.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
