import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">O que é ótimo no HipHop.Dating?</h1>
          <p class="div-of-data-heading">
            "No HipHop.Dating, os namoradores de hip-hop (ou seja, os usuários)
            podem escolher se estão interessados apenas em namoro, oportunidades
            de negócios, avanço na carreira, novas amizades, ou uma combinação
            de duas ou três opções, ou até mesmo todas elas.”
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Diversão e Aventura, Companheirismo, Autoconhecimento, Sentir-se
              Valorizado e Amado, Crescimento Pessoal e Conhecer a Si Mesmo,
              Apoio Emocional, Segurança e Suporte, Expansão Social, Atividades
              Compartilhadas, Intimidade Física e Emocional e mais
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Oportunidades para Colaborações e Parcerias, Aumentando a
              Visibilidade. Conecte-se com indivíduos que ressoam com seus
              valores e cultura e colabore com aqueles que compartilham sua
              visão. Tais conexões podem ser inestimáveis para indivíduos ou
              empresas que buscam novas parcerias ou oportunidades de expansão.
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              Aumentando Sua Visibilidade: Atividade regular e engajamento, como
              postar conteúdo, podem mantê-lo no radar de nossos parceiros.
              Estes incluem gravadoras, produtores de filmes, marcas de moda,
              produtores musicais, editoras, agências de marketing e
              anunciantes, além de colegas da indústria em busca de candidatos
              em potencial, talentos ou colaboradores.
              <br />
              <br /> Marca Pessoal: O HipHop.Dating permite que você construa e
              promova sua marca pessoal ou a si mesmo em escala global.
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Construir amizades em diferentes grupos culturais, sociais ou
              etários pode reduzir preconceitos e estereótipos, promovendo uma
              mentalidade mais inclusiva e um mundo melhor para todos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
