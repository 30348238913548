import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        Присоединяйтесь к нам на HipHop.Dating и погрузитесь в мир
        <br />
        яркое Сердце мировой глобальной культуры.
      </h1>
      <h1 className="nine_heding_mobile">
        Присоединяйтесь к нам на HipHop.Dating и погрузитесь в мир
        <br />
        яркое Сердце мировой глобальной культуры.
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            Мы — сообщество, которое поддерживает единство и прославляет
            <br />
            человечность в целом. Здесь вы найдете динамичное сочетание
            <br />
            знакомств, музыка, веселье, мода и развлечения. Но HipHop.Dating –
            <br />
            это гораздо больше, чем просто что. Хип-хоп, родившийся 11 августа
            <br />
            1973 года в Бронксе, Нью-Йорк, стал первая настоящая глобальная
            <br />
            культура, объединяющая людей всего мира в мирный и цивилизованным
            <br />
            путем.
            <br /> <br />
            Эта культура возникла не силой, как некоторые исторические
            <br />
            культуры движимое религиозными, этническими или военными
            <br />
            влияниями. Вместо этого Хип У Хопа естественным образом развивались,
            <br /> преодолевая первоначальное сопротивление со стороны
            политических, <br />
            мюзикл, и социальных учреждений. Его подлинность и прочная основа
            <br />
            иметь позволили ей восторжествовать и стать ведущей культурной{" "}
            <br />
            силой глобально.
            <br /> <br />
            Сегодня хип-хоп выходит за рамки просто музыкального жанра; это
            <br />
            культурный электростанция, влияющая на моду, политику и многое
            <br />
            другое. Это формирует основная культурная идентичность молодежи{" "}
            <br /> и пожилых людей во всем мире.
            <br /> <br />
            Независимо от вашего музыкального вкуса, HipHop.Dating приглашает
            <br />
            вас на праздник единства, любви, мира и согласия. Здесь вы
            <br />
            можете погрузитесь в мир знакомств, музыки, моды, развлечений
            <br />
            и творчество. Это шанс не только выразить свою индивидуальность
            <br />
            но и зарабатывать деньги посредством своего уникального
            <br />
            самовыражения. Объединиться с нас в динамичном и разнообразном{" "}
            <br /> мире хип-хоп культуры.
          </p>
          <p className="nine_pre_text_mobile">
            Мы — сообщество, которое поддерживает единство и прославляет
            человечество в его полнота. Здесь вы найдете динамичное сочетание
            знакомств, музыки, веселье, мода и развлечения. Но HipHop.Dating –
            это гораздо больше, чем просто что. Хип-хоп родился 11 августа 1973
            года в Бронксе, Нью-Йорк. стать первой настоящей глобальной
            культурой, объединяющей людей со всего мира. мире мирным и
            цивилизованным путем.
            <br /> <br />
            Эта культура возникла не силой, как некоторые исторические культуры,
            движимые религиозными, этническими или военными влияниями. Вместо
            этого хип-хоп естественным образом развился, преодолев
            первоначальные сопротивление со стороны политических, музыкальных и
            социальных учреждений. Его аутентичность и прочная основа позволили
            ему восторжествовать и стать ведущей культурной силой в мире.
            <br /> <br />
            Сегодня хип-хоп выходит за рамки просто музыкального жанра; это
            культурный электростанция, влияющая на моду, политику и многое
            другое. Это формирует основная культурная идентичность молодежи и
            пожилых людей во всем мире.
            <br /> <br />
            Независимо от вашего музыкального вкуса, HipHop.Dating приглашает
            вас на праздник единства, любви, мира и согласия. Здесь вы можете
            нырнуть в мир знакомств, музыки, моды, развлечений и креативность.
            Это шанс не только выразить свою индивидуальность, но и а также
            зарабатывать деньги с помощью своих уникальных выражений.
            Объединяйтесь с нами в динамичный и разнообразный мир культуры
            хип-хопа.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
