import React from "react";
import "./sec7.css";
import sec7_img from "../../../../Assets/new_img_s.png";

const Section7 = () => {
  return (
    <div className="container5">
      <div className="fifth-sec">
        <div className="left-side-font">
          <h1>
            Easy Sign Up & Simple <br />
            To-Use Apps
          </h1>
          <p>
            {" "}
            Your one-stop solution for dating,
            <br />
            entertainment, music, fun and more.
          </p>
          <br />
          <br />
          <p className="pre">
            <b>Discover, Chat, Share & Date.</b>
          </p>
        </div>
        <div className="right-side-img_nine">
          <img src={sec7_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
