import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">؟HipHop.Dating ما الرائع في</h1>
          <p class="div-of-data-heading">
            "في HipHop.Dating، يمكن لبيانات الهيب هوب (المعروفة أيضًا باسم
            المستخدمين) اختيار ما إذا كان ذلك ممكنًا أم لا إنهم مهتمون فقط
            بالمواعدة وفرص العمل والوظيفة التقدم، صداقات جديدة، أو مزيج من اثنين
            أو ثلاثة الخيارات، أو حتى جميعها."
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2_AR">
              التعة والغامرة، الرفقة، اكتشاف الذات، الشعور بالتقدير والحب، النمو
              الشخصي والتعرف على الذات، الدعم العاطفي، المان والدعم، التوسع
              الجتماعي، .النشطة الشتركة، القرب الجسدي والعاطفي، والزيد
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2_AR">
              فرص للتعاون والشراكات، زيادة الرؤية. تواصل مع أفراد يتردد صدى قيمك
              وثقافتك معهم، وتعاون مع من يشاركونك رؤيتك. يمكن أن تكون مثل هذه
              التصالت .ل تقدر بثمن للفراد أو الشركات الباحثي عن شراكات جديدة أو
              فرص للتوسع
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2_AR">
              تعزيز رؤيتك: النشاط النتظم والشاركة، مثل نشر الحتوى، يمكن أن يبقيك
              على رادار شركائنا. يشمل ذلك شركات التسجيلت، النتجي السينمائيي،
              العلمات التجارية للزياء، النتجي الوسيقيي، الناشرين، وكالت التسويق،
              والعلني، بالضافة إلى .أقران الصناعة الذين يبحثون عن مرشحي محتملي،
              مواهب، أو متعاوني
              <br />
              <br /> بناء وترويج علمتك التجارية HipHop.Dating العلمة التجارية
              الشخصية: يتيح لك .الشخصية أو نفسك على نطاق عالي
            </p>

            {/* <p class="right_side_div_2_AR"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2_AR">
              ناء الصداقات عبر مجموعات ثقافية واجتماعية وعمرية مختلفة يمكن أن
              .يقلل من التحيزات والصور النمطية، مما يعزز عقلية أكثر شمولً وعالم
              أفضل للجميع
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
