import React from "react";
import "./sec7.css";
import sec7_img from "../../../../Assets/new_img_s.png";

const Section7 = () => {
  return (
    <div className="container5">
      <div className="fifth-sec">
        <div className="left-side-font">
          <h1>
            आसान साइन अप & सरल <br />
            उपयोग के लिए ऐप
          </h1>
          <p>
            {" "}
            डेटिंग, मनोरंजन, संगीत, मज़ा और
            <br />
            अधिक के लिए आपका एक-स्टॉप समाधान।
          </p>
          <br />
          <br />
          <p className="pre">
            <b>खोजें, चैट करें, साझा करें & डेट करें।.</b>
          </p>
        </div>
        <div className="right-side-img_nine">
          <img src={sec7_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
