import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt_head_f">
            Qu'est-ce qui est génial chez HipHop.Dating?
          </h1>
          <p class="div-of-data-heading">
            "Chez HipHop.Dating, les daters hip-hop (c'est-à-dire, les
            utilisateurs) peuvent choisir s'ils sont intéressés juste par les
            rencontres, les opportunités d'affaires, l'avancement professionnel,
            de nouvelles amitiés, ou une combinaison de deux ou trois options,
            voire toutes.”
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Amusement et Aventure, Compagnie, Découverte de Soi, Se Sentir
              Valoriser et Aimé, Croissance Personnelle et Connaissance de Soi,
              Soutien Émotionnel, Sécurité et Soutien, Expansion Sociale,
              Activités Partagées, Intimité Physique et Émotionnelle, et plus.
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Opportunités pour des Collaborations et des Partenariats,
              Augmentation de la Visibilité. Connectez-vous avec des individus
              qui résonnent avec vos valeurs et votre culture, et collaborez
              avec ceux qui partagent votre vision. De telles connexions peuvent
              être inestimables pour les individus ou les entreprises cherchant
              de nouveaux partenariats ou des opportunités d'expansion.
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              Augmenter Votre Visibilité : Une activité et un engagement
              réguliers, comme la publication de contenu, peuvent vous garder
              sur le radar de nos partenaires. Cela inclut les maisons de
              disques, les producteurs de films, les marques de mode, les
              producteurs de musique, les éditeurs, les agences de marketing et
              les annonceurs, ainsi que les pairs de l'industrie à la recherche
              de candidats potentiels, de talents ou de collaborateurs.
              <br />
              <br /> Marque Personnelle : HipHop.Dating vous permet de
              construire et de promouvoir votre marque personnelle ou vous-même
              à l'échelle mondiale.
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Construire des amitiés à travers différents groupes culturels,
              sociaux ou d'âge peut réduire les préjugés et les stéréotypes,
              favorisant un état d'esprit plus inclusif et un meilleur monde
              pour tous.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
