import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/Japanese.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>本物の⼈々のためのものです、いかさまはありません！</h3>
          <p class="mid_text_p">
            ⾃分⾃身についての短いビデオを投稿し、あなたのビジョン、趣味
            などについて話しましょう。 <br />
            ⾃分⾃身を披露し、あなたの才能で
            デート相⼿やフォロワーを引き付けましょう。
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            ⾃分⾃身についての短いビデオを投稿し、あなたのビジョン、趣味
            などについて話しましょう。⾃分⾃身を披露し、あなたの才能で
            デート相⼿やフォロワーを引き付けましょう。
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
