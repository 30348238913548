import React from "react";
import "./sec7.css";
import sec7_img from "../../../../Assets/new_img_s.png";

const Section7 = () => {
  return (
    <div className="container5">
      <div className="fifth-sec">
        <div className="left-side-font">
          <h1>
            Jiandikishe Kirahisi & <br />
            Tumia Apps kwa Urahisi
          </h1>
          <p>
            {" "}
            Suluhisho lako la moja kwa moja kwa kutongoza,
            <br /> burudani, muziki, raha na zaidi.
          </p>
          <br />
          <br />
          <p className="pre">
            <b>Gundua, Chat, Shiriki & Tongoza.</b>
          </p>
        </div>
        <div className="right-side-img_nine">
          <img src={sec7_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
