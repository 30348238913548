import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        加⼊我们 HipHop.Dating，沉浸在 <br /> 世界全球⽂化的热烈核⼼。
      </h1>
      <h1 className="nine_heding_mobile">
        Join us at HipHop.Dating and immerse yourself in the vibrant Heart of
        the world’s global culture.
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            我们是⼀个拥抱团结并庆祝⼈类完整性的社区。在这⾥，你会发现约 <br />
            会、⾳乐、乐趣、时尚和娱乐的动态融合。但HipHop.Dating不仅仅
            <br />
            是这些。嘻哈，诞⽣于1973年8⽉11⽇，纽约布朗克斯，已成为第⼀
            <br />
            个真正的全球⽂化，以和平⽂明的⽅式将世界各地的⼈们团结起来。
            <br />
            <br /> <br />
            这种⽂化并不是像某些历史⽂化那样通过宗教、种族或军事影响⼒⽽
            <br />
            强⾏出现的。相反，嘻哈⾃然演变，克服了来⾃政治、⾳乐和社会机
            <br />
            构的最初抵抗。它的真实性和坚实基础使它得以胜利，并成为全球领
            <br />
            先的⽂化⼒量。
            <br /> <br />
            今天，嘻哈超越了仅仅是⾳乐类型的身份；它是影响时尚、政治等的
            <br />
            ⽂化巨头。它形成了全球⻘年和⽼年⼈的核⼼⽂化身份。
            <br /> <br />
            ⽆论你的⾳乐品味如何，HipHop.Dating欢迎你参与到团结、爱、和
            <br />
            平和和谐的庆祝活动中。在这⾥，你可以深⼊约会、⾳乐、时尚、娱
            <br />
            乐和创造⼒的世界。这不仅是表达个性的机会，也是通过你独特的表
            <br />
            达⽅式赚钱的机会。加⼊我们，在多元和丰富的嘻哈⽂化世界中团结
            <br /> 起来。
          </p>
          <p className="nine_pre_text_mobile">
            我们是⼀个拥抱团结并庆祝⼈类完整性的社区。在这⾥，你会发现约
            会、⾳乐、乐趣、时尚和娱乐的动态融合。但HipHop.Dating不仅仅
            是这些。嘻哈，诞⽣于1973年8⽉11⽇，纽约布朗克斯，已成为第⼀
            个真正的全球⽂化，以和平⽂明的⽅式将世界各地的⼈们团结起来。
            <br /> <br />
            这种⽂化并不是像某些历史⽂化那样通过宗教、种族或军事影响⼒⽽
            强⾏出现的。相反，嘻哈⾃然演变，克服了来⾃政治、⾳乐和社会机
            构的最初抵抗。它的真实性和坚实基础使它得以胜利，并成为全球领
            先的⽂化⼒量。
            <br /> <br />
            今天，嘻哈超越了仅仅是⾳乐类型的身份；它是影响时尚、政治等的
            ⽂化巨头。它形成了全球⻘年和⽼年⼈的核⼼⽂化身份。
            <br /> <br />
            ⽆论你的⾳乐品味如何，HipHop.Dating欢迎你参与到团结、爱、和
            平和和谐的庆祝活动中。在这⾥，你可以深⼊约会、⾳乐、时尚、娱
            乐和创造⼒的世界。这不仅是表达个性的机会，也是通过你独特的表
            达⽅式赚钱的机会。加⼊我们，在多元和丰富的嘻哈⽂化世界中团结 起来。
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
