import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>Kuhusu</h1>
      <p className="sec2_p_web">
        {" "}
        <b> HipHop.Dating</b> ni jukwaa la kimataifa la kutongoza na burudani ya
        kijamii.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> ni jukwaa la kimataifa la <br />
        kutongoza na burudani ya kijamii.
      </p>
      <p className="pre-1">
        {" "}
        Chapisha video fupi kuhusu wewe ni nani kwa dhati! Uwe halisi, ruhusu
        mtu wako wa kutongoza akukubali kama ulivyo, <br />
        vutia wafuasi zaidi, na ufanye pesa zaidi.
      </p>
      <p className="pre-1-mobile">
        {" "}
        Chapisha video fupi kuhusu wewe ni nani kwa dhati! <br />
        Uwe halisi, ruhusu mtu wako wa kutongoza akukubali <br />
        kama ulivyo, vutia wafuasi zaidi, na ufanye pesa zaidi.
      </p>

      <p className="pre-1">
        Onyesha vipaji vyako vya kipekee, kuanzia kucheza na mitindo hadi
        kuimba, kurap, au ucheshi, na uchukue
        <br /> umakini wa dunia. Tumia jukwaa hili kung'aa na kufungua milango
        kwa fursa mpya.
      </p>
      <p className="pre-1-mobile">
        Onyesha vipaji vyako vya kipekee, kuanzia kucheza <br />
        na mitindo hadi kuimba, kurap, au ucheshi, na uchukue <br />
        umakini wa dunia. Tumia jukwaa hili kung'aa na kufungua <br />
        milango kwa fursa mpya.
      </p>
      <p className="pre-1">
        Ndio, ingia katika ulimwengu wa kusisimua wa <b>HipHop.Dating</b>,
        marudio ya mwisho kwa watu wapweke na
        <br />
        wanaotafuta raha, mawasiliano, na gusa ya showbiz! Rukia katika uzoefu
        wa kijamii uliojaa msisimko
        <br />
        <b>
          <i>
            ambapo wewe si tu mwingine kwenye wasifu, bali ni nyota inayosubiri
            kung'aa.
          </i>
        </b>
        Fichua wewe wa
        <br />
        kweli na kipengele chetu cha video — njia ya kipekee kujitambulisha wewe
        halisi.
        <br />
        Uwe jasiri, uwe halisi, na ruhusu mechi zako zijeruhiwe kwa mtu wewe
        kweli ni.
      </p>
      <p className="pre-1-mobile">
        Ndio, ingia katika ulimwengu wa kusisimua wa <br />
        <b>HipHop.Dating</b>, marudio ya mwisho kwa watu wapweke na <br />
        wanaotafuta raha, mawasiliano, na gusa ya showbiz! <br /> Rukia katika
        uzoefu wa kijamii uliojaa msisimko <br />
        <b>
          <i>
            ambapo wewe si tu mwingine kwenye wasifu, <br /> bali ni nyota
            inayosubiri kung'aa.
          </i>
        </b>
        . <br />
        Fichua wewe wa kweli na kipengele
        <br />
        chetu cha video — njia ya kipekee kujitambulisha wewe
        <br />
        halisi. Uwe jasiri, uwe halisi, na ruhusu mechi zako
        <br />
        zijeruhiwe kwa mtu wewe kweli ni.
      </p>
      <p className="pre-1">
        Lakini kuna zaidi! Badilisha safari yako ya kutongoza kuwa sherehe ya
        vipaji vyako. Iwe wewe ni <br />
        mdansi mtindo, ikoni ya mitindo, mwimbaji au rapa mwenye melodi, au una
        uwezo wa kuwafanya watu <br />
        wacheke, <b>HipHop.Dating</b> ni jukwaa lako. Onyesha ujuzi wako maalum,
        vutia kundi la wapenzi,
        <br />
        na tazama idadi yako ya wafuasi ikiongezeka. Na unajua nini? Kipaji
        chako si tu kinashinda mioyo; <br />
        pia kinaweza kuwa njia ya faida. Fanya mawasiliano, pata wafuasi, na
        uwezekano ongeza mapato yako, <br />
        wakati ukifurahia msisimko wa kutongoza na mitandao ya kijamii. <br />
      </p>

      <p className="pre-1-mobile-hi">
        Lakini kuna zaidi! Badilisha safari yako ya kutongoza kuwa sherehe ya
        vipaji vyako. Iwe wewe ni mdansi mtindo, ikoni ya mitindo, mwimbaji au
        rapa mwenye melodi, au una uwezo wa kuwafanya watu wacheke,{" "}
        <b>HipHop.Dating</b> ni jukwaa lako. Onyesha ujuzi wako maalum, vutia
        kundi la wapenzi, na tazama idadi yako ya wafuasi ikiongezeka. Na unajua
        nini? Kipaji chako si tu kinashinda mioyo; pia kinaweza kuwa njia ya
        faida. Fanya mawasiliano, pata wafuasi, na uwezekano ongeza mapato yako,
        wakati ukifurahia msisimko wa kutongoza na mitandao ya kijamii.
      </p>
      <p className="pre-1">
        Jiandae kuwa sehemu ya jamii yenye shauku ambapo msisimko hauna mwisho
        na kila swipe inaweza <br />
        kukuletea hadithi mpya ya kushangaza. Jiunge na <b>HipHop.Dating</b> leo
        na anza safari iliyojaa furaha, mtindo,
        <br />
        na uwezekano mkubwa!
      </p>

      <p className="pre-1-mobile-hi">
        Jiandae kuwa sehemu ya jamii yenye shauku ambapo msisimko hauna mwisho
        na kila swipe inaweza kukuletea hadithi mpya ya kushangaza. Jiunge na{" "}
        <b>HipHop.Dating</b> leo na anza safari iliyojaa furaha, mtindo, na
        uwezekano mkubwa!
      </p>
    </div>
  );
};

export default Section2;
