import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/Arabic.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>!مخصص للشخاص الحقيقيي، بدون حيل</h3>
          <p class="mid_text_p">
            هواياتك والزيد. عرض نفسك .ومواهبك لجذب الواعيد والتابعي
            <br />
            انشر فيديوهات قصيرة عن نفسك، تحدث عنك، عن رؤيتك،
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            انشر فيديوهات قصيرة عن نفسك، تحدث عنك، عن رؤيتك، هواياتك والزيد. عرض
            نفسك .ومواهبك لجذب الواعيد والتابعي
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
