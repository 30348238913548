import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">¿Qué es lo genial de HipHop.Dating?</h1>
          <p class="div-of-data-heading">
            "En HipHop.Dating, los usuarios de hiphop (también conocidos como,
            usuarios) pueden elegir si están interesados solo en citas,
            oportunidades de negocio, avance profesional, nuevas amistades, o
            una combinación de dos o tres opciones, o incluso todas ellas.”
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Diversión y Aventura, Compañerismo, Auto-descubrimiento, Sentirse
              Valorado y Amado, Crecimiento Personal y Conocerse a Uno Mismo,
              Apoyo Emocional, Seguridad y Apoyo, Expansión Social, Actividades
              Compartidas, Intimidad Física y Emocional, y más
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Oportunidades para Colaboraciones y Asociaciones, Aumento de la
              Visibilidad. Conéctate con individuos que resuenen con tus valores
              y cultura, y colabora con aquellos que compartan tu visión. Tales
              conexiones pueden ser invaluables para individuos o empresas que
              buscan nuevas asociaciones u oportunidades de expansión.
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              Mejorando Tu Visibilidad: La actividad y el compromiso regulares,
              como publicar contenido, pueden mantenerte en el radar de nuestros
              socios. Estos incluyen discográficas, productores de cine, marcas
              de moda, productores musicales, editoriales, agencias de marketing
              y anunciantes, así como colegas de la industria en busca de
              candidatos potenciales, talentos o colaboradores.
              <br />
              <br /> Marca Personal: HipHop.Dating te permite construir y
              promocionar tu marca personal o a ti mismo a escala global.
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Nuevos Amigos: Construir amistades a través de diferentes grupos
              culturales, sociales o de edad puede reducir prejuicios y
              estereotipos, promoviendo una mentalidad más inclusiva y un mundo
              mejor para todos.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
