import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/Russian.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>для настоящих людей, без уловок!</h3>
          <p class="mid_text_p">
            Публикуйте короткие видео о себе, рассказывайте о себе, ваших
            видениях, увлечениях и т.д.
            <br /> Продемонстрируйте себя, свои таланты и привлеките своего
            партнера и подписчиков.
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            Публикуйте короткие видео о себе, рассказывайте о себе, ваших
            видениях, увлечениях и т.д. Продемонстрируйте себя, свои таланты и
            привлеките своего партнера и подписчиков.
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
