import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/chinese.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>是给真实的⼈，没有噱头！</h3>
          <p class="mid_text_p">
            发布关于⾃⼰的短视频，谈谈你、你的愿景、你的爱好等。 展示⾃⼰，{" "}
            <br />
            你的才能，并吸引你的约会对象和追随者。
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            发布关于⾃⼰的短视频，谈谈你、你的愿景、你的爱好等。
            展示⾃⼰，你的才能，并吸引你的约会对象和追随者。
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
