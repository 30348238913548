import React from "react";
import tick from "../../Assets/thankyou/check.png";
import { useNavigate } from "react-router-dom";
import "./Thankyou.css";

const Thankyou = () => {
  const navigate = useNavigate();
  return (
    <div className="main-sec">
      <div className="text">
        <img src={tick} alt="" />
        <h1>Thank You For Contacting Us</h1>
        <p>
          We appreciate that you've taken the time to write us <br />
          We'll get back to you very soon.
        </p>
        <div onClick={() => navigate("/")} className="button_back">
          Go to Website
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
