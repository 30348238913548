import { useState, useEffect, useRef } from "react";
import "./nav.css";
import logo from "../../../Assets/logo.png";
import logo2 from "../../../Assets/logo2.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { Link } from "react-scroll";

const Navbar = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [lang, setlang] = useState();
  // console.log("🚀 ~ Navbar ~ lang:", lang);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  const Navigate = useNavigate();

  let menuref = useRef();

  const [open, setIsopen] = useState(false);
  const [onshow, setonshow] = useState(false);

  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setonshow(false);
          // setshow(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);
  const toggle = (item) => {
    // console.log("navbar item clicked", item);
    setIsopen(false);
    // setshow(false);
  };
  const handlehide = () => {
    setIsopen(!open);
    // setshow(false);
  };
  const pathname = useLocation();
  // console.log("🚀 ~ Navbar ~ pathname:", pathname);
  useEffect(() => {
    setlang(pathname.pathname);
  }, [pathname]);

  const Langchngae = (e) => {
    const Link = e.target.value;
    Navigate(`${Link}`);
  };

  return (
    <div ref={menuref} className="Main_navbar">
      <div className="mainnav">
        <nav id="navbar">
          <Link
            to="Sec1"
            offset={-100}
            duration={500}
            smooth={true}
            className="logo">
            <img src={logo} style={{ width: "100%" }} />
          </Link>
          <Link
            to="Sec1"
            offset={-100}
            duration={500}
            smooth={true}
            className="logo2">
            <img src={logo2} style={{ width: "70%" }} />
          </Link>

          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <Link
                to="Sec1"
                offset={-100}
                duration={500}
                smooth={true}
                onClick={() => toggle("")}>
                {lang == "/French"
                  ? "Accueil"
                  : lang == "/Spanish"
                  ? "Inicio"
                  : lang == "/Hindi"
                  ? "होम"
                  : lang == "/Chinese"
                  ? "主⻚"
                  : lang == "/Swahili"
                  ? "Nyumbani"
                  : lang == "/Portuguese"
                  ? "CASA"
                  : lang == "/Russian"
                  ? "ГЛАВНАЯ"
                  : lang == "/German"
                  ? "STARTSEITE"
                  : lang == "/Japanese"
                  ? "ホーム"
                  : lang == "/Arabic"
                  ? "بيت"
                  : "Home"}
              </Link>
            </li>
            <li onMouseMove={() => setonshow(true)}>
              <Link
                to="Sec2"
                offset={-100}
                duration={500}
                smooth={true}
                onClick={() => toggle("")}>
                {lang == "/French"
                  ? "À PROPOS DE NOUS"
                  : lang == "/Spanish"
                  ? "SOBRE NOSOTROS"
                  : lang == "/Hindi"
                  ? "हमारे बारे में"
                  : lang == "/Chinese"
                  ? "关于我们"
                  : lang == "/Swahili"
                  ? "Kuhusu Sisi"
                  : lang == "/Portuguese"
                  ? "SOBRE NÓS"
                  : lang == "/Russian"
                  ? "О НАС"
                  : lang == "/German"
                  ? "ÜBER UNS"
                  : lang == "/Japanese"
                  ? "私達について"
                  : lang == "/Arabic"
                  ? "حولنا"
                  : "About"}
              </Link>
            </li>
            {/* <li>
              <Link
                to="Sec5"
                offset={-100}
                duration={500}
                smooth={true}
                onClick={() => toggle("")}>
                {lang == "/Turkish"
                  ? "Misyon"
                  : lang == "/French"
                  ? "Mission"
                  : lang == "/Portuguese"
                  ? "Missão"
                  : lang == "/Swahili"
                  ? "Dhamira"
                  : lang == "/Spanish"
                  ? "Misión"
                  : "Mission"}
              </Link>
            </li> */}

            <li>
              <Link
                to="Contact"
                offset={-100}
                duration={500}
                smooth={true}
                onClick={() => toggle("")}>
                {lang == "/French"
                  ? "CONTACTEZ-NOUS"
                  : lang == "/Spanish"
                  ? "CONTÁCTANOS"
                  : lang == "/Hindi"
                  ? "संपर्क करें"
                  : lang == "/Chinese"
                  ? "联系我们"
                  : lang == "/Swahili"
                  ? "Wasiliana Nasi"
                  : lang == "/Portuguese"
                  ? "CONTACTE-NOS"
                  : lang == "/Russian"
                  ? "КОНТАКТЫ"
                  : lang == "/German"
                  ? "KONTAKT"
                  : lang == "/Japanese"
                  ? "お問い合わせ"
                  : lang == "/Arabic"
                  ? "اتصل بنا"
                  : "Contact"}
              </Link>
            </li>
            <button className="download_btn">
              <Link to="Sec9" offset={-100} duration={500} smooth={true}>
                {lang == "/French"
                  ? "Télécharger l'application"
                  : lang == "/Spanish"
                  ? "Descargar App"
                  : lang == "/Hindi"
                  ? "ऐप डाउनलोड करें"
                  : lang == "/Chinese"
                  ? "下载应⽤程序"
                  : lang == "/Swahili"
                  ? "Pakua App"
                  : lang == "/Portuguese"
                  ? "Baixe o Aplicativo"
                  : lang == "/Russian"
                  ? "Скачать приложение"
                  : lang == "/German"
                  ? "App herunterladen"
                  : lang == "/Japanese"
                  ? "アプリダウンロード"
                  : lang == "/Arabic"
                  ? "تحميل التطبيق"
                  : "Download App"}
              </Link>
            </button>
            <select name="Lang" id="Lang" value={lang} onChange={Langchngae}>
              <option value="/">English</option>
              <option value="/French">French</option>
              <option value="/Spanish">Spanish</option>
              <option value="/Hindi">Hindi</option>
              <option value="/Chinese">Chinese</option>
              <option value="/Swahili">Swahili</option>
              <option value="/Portuguese">Portuguese</option>
              <option value="/Russian">Russian</option>
              <option value="/German">German</option>
              <option value="/Japanese">Japanese</option>
              <option value="/Arabic">Arabic</option>
            </select>
          </ul>

          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
        {/* <Outlet /> */}
      </div>
    </div>
  );
};

export default Navbar;
// path name :-  "/"          language name :-English
// path name :-  "/Turkish"   language name :-Turkish
// path name :-   "/French"   language name :-French
// path name :-  "/Portuguese" language name :-Portuguese
// path name :-  "/Spanish"   language name :-Spanish
// path name :-  "/Swahili"   language name :-Swahili
