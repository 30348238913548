import React from "react";
import "./sec1.css";
import applestore from "../../../../Assets/iOS_btn.png";
import playstore from "../../../../Assets/play_store_btn.png";
import sec1img from "../../../../Assets/header_img.png";
const Section1 = () => {
  return (
    <div className="head-sec" id="Sec1">
      <div className="cont-1">
        <div className="text-area">
          <h1 className="f_h1">
            É Mais do que Namoro<span>&trade;</span>
          </h1>
          <p>
            É uma celebração de amor, <br />
            vida, música, diversão &<br />
            moda e ganhar dinheiro <br /> ao mesmo tempo.
          </p>
          <div className="img2">
            <p>Em Breve!</p>
            <div className="box-1">
              <img src={applestore} alt="" />
              <img src={playstore} alt="" />
            </div>
          </div>
        </div>
        <div className="header-img">
          <img src={sec1img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
