import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/Portuguese.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>é para pessoas reais, sem truques!</h3>
          <p class="mid_text_p">
            Poste vídeos curtos sobre si mesmo, fale sobre você, suas visões,
            seus hobbies, etc.
            <br /> Mostre-se, seus talentos e atraia seu par e seguidores.
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            Poste vídeos curtos sobre si mesmo, fale sobre você, suas visões,
            seus hobbies, etc. Mostre-se, seus talentos e atraia seu par e
            seguidores.
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
