import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>Why and how are we</h1>
          <h1>different?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              The advantage of HipHop.Dating in comparison
              <br />
              with many other dating platforms is that all
              <br />
              users already have a common major passion in
              <br />
              life which is the “Love of Hip Hop and its culture”.
              <br />
              <br />
              Other platforms allowing users to prescreen the <br /> potential
              matches they see, filtering out
              <br />
              undesirable races, ages, or locations. You filter, <br /> you
              indicate “interested/not,” you repeat, etc. <br /> Some apps are
              using the algorithms choosing <br /> who to show you, and when.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              The lack of the basic common interest, passion <br /> and love
              among users renders it difficult for
              <br /> many dating apps to realistically help users to <br /> find
              their soulmates.
              <br />
              <br />
              At <b>HipHop.Dating</b>, users already know that they
              <br />
              share the same interest, passion and love for
              <br />
              Hip Hop and its culture (lifestyle, music, fashion,
              <br />
              dance, poetry, arts, etc). They can simply chat,
              <br />
              date, share and discover each other naturally.
              <br />
              <br />
              In addition, <b>Hip Hop Daters</b> (users) can make
              <br />
              money by creating and posting contents while having fun.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
