import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>我们与众不同的原因和⽅式是什么？</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              与许多其他约会平台相⽐，HipHop.Dating <br />
              的优势在于所有⽤户都有 ⼀个共同的主要热情， <br />{" "}
              那就是“对嘻哈及其⽂化的热爱”。
              <br />
              <br />
              其他平台允许⽤户预先筛选他们看到的潜在
              <br />
              匹配对象, br过滤掉不想要 的种族、
              <br />
              年龄或地点。你筛选， 你表明 <br />
              “感兴趣/不感兴趣”，然后重复 等等。
              <br />
              ⼀些应⽤程序使⽤算法选择向你展示的⼈，
              <br />
              以及何时展示。
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              缺乏基本的共同兴趣、热情和爱情使得许多约会应⽤难以实际帮助⽤
              户找到他们的灵魂伴侣。
              <br />
              <br />在 <b>HipHop.Dating</b>
              ，⽤户已经知道他们对嘻哈及其⽂化（⽣活⽅式、
              ⾳乐、时尚、舞蹈、诗歌、艺术等）有着共同的兴趣、热情和爱。他
              们可以简单地聊天、约会、分享并⾃然地发现彼此。
              <br />
              <br />
              此外，<b>Hip Hop Daters</b>{" "}
              （⽤户）在玩乐的同时通过创建和发布内容赚钱。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
