import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>Sobre</h1>
      <p className="sec2_p_web">
        <b> HipHop.Dating</b>.Dating é uma plataforma global de namoro e
        entretenimento social.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> .Dating é uma plataforma <br /> global de namoro e
        entretenimento social.
      </p>
      <p className="pre-1">
        {" "}
        Poste vídeos curtos sobre quem você realmente é! Seja autêntico, deixe
        seu par aceitar você como você é,
        <br />
        atraia mais seguidores e ganhe mais dinheiro.
      </p>
      <p className="pre-1-mobile">
        {" "}
        Poste vídeos curtos sobre quem você realmente é! <br /> Seja autêntico,
        deixe seu par aceitar você como você é,
        <br />
        atraia mais seguidores e ganhe mais dinheiro.
      </p>

      <p className="pre-1">
        Mostre seus talentos únicos, desde dança e moda até cantar, fazer rap ou
        comédia, e chame <br />a atenção do mundo. Use esta plataforma para
        brilhar e abrir portas para novas oportunidades.
      </p>
      <p className="pre-1-mobile">
        Mostre seus talentos únicos, desde dança e moda até <br />
        cantar, fazer rap ou comédia, e chame a atenção do
        <br /> mundo. Use esta plataforma para brilhar e abrir
        <br /> portas para novas oportunidades.
      </p>
      <p className="pre-1">
        Sim, entre no mundo eletrizante do <b>HipHop.Dating</b>, o destino final
        para solteiros e <br />
        pessoas procurando diversão, conexão e um toque de showbiz! Mergulhe em
        uma experiência social empolgante
        <br />
        <b>
          <i>
            onde você não é apenas mais um perfil, mas uma estrela pronta para
            brilhar
          </i>
        </b>
        . Liberte seu verdadeiro eu com <br />
        nosso recurso de vídeo — uma maneira única de se apresentar. Seja
        ousado,seja verdadeiro,
        <br />e deixe que os possíveis pares se apaixonem por quem você
        realmente é.
      </p>
      <p className="pre-1-mobile-hi">
        Sim, entre no mundo eletrizante do <b>HipHop.Dating</b>, o destino final
        para solteiros e pessoas procurando diversão, conexão e um toque de
        showbiz! Mergulhe em uma experiência social empolgante.
        <b>
          <i>
            onde você não é apenas mais um perfil, mas uma estrela pronta para
            brilhar
          </i>
        </b>
        . Liberte seu verdadeiro eu com nosso recurso de vídeo — uma maneira
        única de se apresentar. Seja ousado,seja verdadeiro, e deixe que os
        possíveis pares se apaixonem por quem você realmente é.
      </p>
      <p className="pre-1">
        Mas tem mais! Transforme sua jornada de namoro em uma celebração de seus
        talentos. Se você é <br />
        um dançarino animado, um ícone de moda, um cantor ou rapper melódico, ou
        tem um talento para fazer as pessoas <br />
        lrirem, o <b> HipHop.Dating</b> é seu palco. Exiba suas habilidades
        especiais, cative uma audiência de admiradores,
        <br />
        e veja seu número de seguidores aumentar. E adivinha? Seu talento não só
        conquista corações; ele também pode <br />
        se transformar em uma avenida lucrativa. Faça conexões, ganhe seguidores
        e potencialmente aumente seus <br />
        ganhos, tudo enquanto desfruta da emoção de namorar e de se conectar em
        redes sociais. <br />
      </p>
      <p className="pre-1">
        Prepare-se para fazer parte de uma comunidade vibrante onde a emoção
        nunca termina e cada deslizar pode <br />
        levar a uma nova história incrível. Junte-se ao <b>
          HipHop.Dating
        </b>{" "}
        hoje e comece uma jornada cheia de diversão, estilo,
        <br />e possibilidades fantásticas!
      </p>

      <p className="pre-1-mobile-hi">
        Mas tem mais! Transforme sua jornada de namoro em uma celebração de seus
        talentos. Se você é um dançarino animado, um ícone de moda, um cantor ou
        rapper melódico, ou tem um talento para fazer as pessoas rirem, o
        <b> HipHop.Dating</b> é seu palco. Exiba suas habilidades especiais,
        cative uma audiência de admiradores, e veja seu número de seguidores
        aumentar. E adivinha? Seu talento não só conquista corações; ele também
        pode se transformar em uma avenida lucrativa. Faça conexões, ganhe
        seguidores e potencialmente aumente seus ganhos, tudo enquanto desfruta
        da emoção de namorar e de se conectar em redes sociais.
      </p>
      <p className="pre-1-mobile-hi">
        Prepare-se para fazer parte de uma comunidade vibrante onde a emoção
        nunca termina e cada deslizar pode levar a uma nova história incrível.
        Junte-se ao <b>HipHop.Dating</b> hoje e comece uma jornada cheia de
        diversão, estilo, e possibilidades fantásticas!
      </p>
    </div>
  );
};

export default Section2;
