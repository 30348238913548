import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        Jiunge nasi katika HipHop.Dating na ujipatie uzoefu katika moyo <br />
        wenye nguvu wa utamaduni wa kimataifa wa dunia.
      </h1>
      <h1 className="nine_heding_mobile">
        Jiunge nasi katika HipHop.Dating na ujipatie uzoefu katika moyo wenye
        nguvu wa utamaduni wa kimataifa wa dunia.
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            Sisi ni jamii inayokumbatia umoja na kusherehekea ubinadamu <br />
            kwa ujumla wake. Hapa, utapata mchanganyiko wa kipekee wa kutongoza,
            <br />
            muziki, raha, mitindo, na burudani. Lakini HipHop.Dating ni zaidi ya
            hayo. <br />
            Hip Hop, iliyozaliwa Agosti 11, 1973, Bronx, New York, imekuwa{" "}
            <br />
            utamaduni wa kwanza wa kweli wa kimataifa, ukiunganisha watu kote
            <br />
            duniani kwa njia ya amani na ya kistaarabu.
            <br /> <br />
            Utamaduni huu umeibuka sio kwa nguvu, kama baadhi ya utamaduni wa
            <br />
            kihistoria unaosukumwa na ushawishi wa kidini, kikabila, au kijeshi.
            <br />
            Badala yake, Hip Hop imekuwa kiasili, ikishinda upinzani wa awali
            kutoka <br />
            kwa taasisi za kisiasa, kimuziki, na kijamii. Uhalisi wake na msingi
            <br />
            imara umewezesha ushindi wake na kuwa nguvu ya utamaduni inayoongoza
            <br />
            duniani.
            <br /> <br />
            Leo hii, Hip Hop inazidi kuwa tu aina ya muziki; ni nguvu ya
            utamaduni
            <br />
            inayoathiri mitindo, siasa, na zaidi. Inaunda
            <br />
            utambulisho wa msingi wa kitamaduni kwa vijana na wazee duniani
            kote.
            <br /> <br />
            Bila kujali ladha yako ya muziki, HipHop.Dating inakukaribisha
            kwenye
            <br />
            sherehe ya umoja, upendo, amani, na maelewano. Hapa, unaweza
            <br />
            kuzama katika dunia ya kutongoza, muziki, mitindo, burudani
            <br />
            na ubunifu. Ni nafasi sio tu ya kuelezea utu wako <br />
            bali pia kufanya pesa kupitia maonyesho yako ya kipekee. Jiunge{" "}
            <br />
            nasi katika dunia ya kipekee na tofauti ya utamaduni wa Hip Hop.{" "}
          </p>
          <p className="nine_pre_text_mobile">
            Sisi ni jamii inayokumbatia umoja na kusherehekea ubinadamu kwa
            ujumla wake. Hapa, utapata mchanganyiko wa kipekee wa kutongoza,
            muziki, raha, mitindo, na burudani. Lakini HipHop.Dating ni zaidi ya
            hayo. Hip Hop, iliyozaliwa Agosti 11, 1973, Bronx, New York, imekuwa{" "}
            utamaduni wa kwanza wa kweli wa kimataifa, ukiunganisha watu kote
            duniani kwa njia ya amani na ya kistaarabu.
            <br /> <br />
            Utamaduni huu umeibuka sio kwa nguvu, kama baadhi ya utamaduni wa
            kihistoria unaosukumwa na ushawishi wa kidini, kikabila, au kijeshi.
            Badala yake, Hip Hop imekuwa kiasili, ikishinda upinzani wa awali
            kutoka kwa taasisi za kisiasa, kimuziki, na kijamii. Uhalisi wake na
            msingi imara umewezesha ushindi wake na kuwa nguvu ya utamaduni
            inayoongoza duniani.
            <br /> <br />
            Leo hii, Hip Hop inazidi kuwa tu aina ya muziki; ni nguvu ya
            utamaduni inayoathiri mitindo, siasa, na zaidi. Inaunda utambulisho
            wa msingi wa kitamaduni kwa vijana na wazee duniani kote.
            <br /> <br />
            Bila kujali ladha yako ya muziki, HipHop.Dating inakukaribisha
            kwenye sherehe ya umoja, upendo, amani, na maelewano. Hapa, unaweza
            kuzama katika dunia ya kutongoza, muziki, mitindo, burudani na
            ubunifu. Ni nafasi sio tu ya kuelezea utu wako bali pia kufanya pesa
            kupitia maonyesho yako ya kipekee. Jiunge nasi katika dunia ya
            kipekee na tofauti ya utamaduni wa Hip Hop.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
