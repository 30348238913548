import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">HipHop.Dating के बारेमेंक्या खास है?</h1>
          <p class="div-of-data-heading">
            "HipHop.Dating पर, हिपहॉप डेटर्स (अर्थात्उपयोगकर्ता) यह चुन
            सकतेहैंकि वे के वल डेटिंग मेंरुचि रखतेहैं, व्यावसायिक अवसरों में,
            करियर की प्रगति में, नई दोस्ती में, या दो या तीन विकल्पों के संयोजन
            में, या फिर इन सभी में।"
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              मज़ा और रोमांच, साथी, आत्म-खोज, महत्वपूर्णऔर प्रेमित महसूस करना,
              व्यक्तिगत विकास और स्वयं को जानना, भावनात्मक सहारा, सुरक्षा और
              समर्थन, सामाजिक विस्तार, साझा गतिविधियाँ, शारीरिक और भावनात्मक
              निकटता, और भी बहुत कु छ।
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              सहयोग और साझेदारी के अवसर, दृश्यता बढ़ाना। उन व्यक्तियों के साथ
              जुड़ेंजो आपके मूल्यों और संस्कृति सेप्रतिध्वनित होतेहैं, और उनके
              साथ सहयोग करेंजो आपके दृष्टिकोण को साझा करतेहैं। ऐसेसंबंध नई
              साझेदारियों या विस्तार के अवसरों की तलाश कर रहेव्यक्तियों या
              व्यवसायों के लिए अमूल्य हो सकते हैं।
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              अपनी दृश्यता बढ़ाना: नियमित गतिविधि और संलग्नता, जैसेकि सामग्री
              पोस्ट करना, आपको हमारेसाझेदारों की रडार पर बनाए रख सकता है। इनमें
              रिकॉर्डलेबल, फिल्म निर्माता, फै शन ब्रांड, संगीत निर्माता,
              प्रकाशक, मार्केटिंग एजेंसियाँ, और विज्ञापनदाता, साथ ही उद्योग साथी
              शामिल हैंजो संभावित उम्मीदवारों, प्रतिभाओ ं, या सहयोगियों की तलाश
              मेंहैं।
              <br />
              <br /> व्यक्तिगत ब्रांडिंग: HipHop.Dating आपको वैश्विक स्तर पर
              अपनेव्यक्तिगत ब्रांड या खुद को बनानेऔर प्रचारित करनेकी अनुमति देता
              है।
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              {" "}
              विभिन्न सांस्कृतिक, सामाजिक, या आयुसमूहों मेंदोस्ती
              बनानेसेपूर्वाग्रह और सांस्कृतिक सीमाओ ंको कम कर सकतेहैं, एक अधिक
              समावेशी मानसिकता को बढ़ावा देतेहुए और सभी के लिए बेहतर दुनिया की
              स्थापना करतेहैं।
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
