import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        Schließe dich uns bei HipHop.Dating an und tauche <br />
        ein in das pulsierende Herz der globalen Kultur der Welt.
      </h1>
      <h1 className="nine_heding_mobile">
        Join us at HipHop.Dating and immerse yourself in the vibrant Heart of
        the world’s global culture.
      </h1>
      <div className="nine-sec-gr">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine_gr">
          <p className="nine_pre_text">
            Wir sind eine Gemeinschaft, die Einheit umarmt und die
            Menschlichkeit in ihrer Gesamtheit feiert. Hier findest du eine
            dynamische Fusion aus Dating, Musik, Spaß, Mode und Unterhaltung.
            Aber HipHop.Dating ist viel mehr als das. Hip Hop, geboren am 11.
            August 1973 in der Bronx, New York, ist zur ersten wahren globalen
            Kultur geworden, die Menschen auf der ganzen Welt auf friedliche und
            zivilisierte Weise vereint.
            <br /> <br />
            Diese Kultur hat sich nicht durch Zwang entwickelt, wie einige
            historische Kulturen, die von religiösen, ethnischen oder
            militärischen Einflüssen angetrieben wurden. Stattdessen hat sich
            Hip Hop natürlich entwickelt und anfänglichen Widerstand von
            politischen, musikalischen und sozialen Institutionen überwunden.
            Seine Authentizität und solide Grundlage haben es ermöglicht, zu
            triumphieren und zur führenden kulturellen Kraft weltweit zu werden.
            <br /> <br />
            Heute geht Hip Hop über ein bloßes Musikgenre hinaus; es ist eine
            kulturelle Kraft, die Mode, Politik und mehr beeinflusst. Es bildet
            die Kernkulturidentität für Jugendliche und Ältere weltweit.
            <br /> <br />
            Unabhängig von deinem musikalischen Geschmack heißt dich
            HipHop.Dating willkommen zu einer Feier der Einheit, Liebe, des
            Friedens und der Harmonie. Hier kannst du in die Welt des Datings,
            der Musik, Mode, Unterhaltung und Kreativität eintauchen. Es ist
            eine Chance, nicht nur deine Individualität auszudrücken, sondern
            auch durch deine einzigartigen Ausdrucksformen Geld zu verdienen.
            Vereine dich mit uns in der dynamischen und vielfältigen Welt der
            Hip Hop-Kultur.
          </p>
          <p className="nine_pre_text_mobile">
            Wir sind eine Gemeinschaft, die Einheit umarmt und die
            Menschlichkeit in ihrer Gesamtheit feiert. Hier findest du eine
            dynamische Fusion aus Dating, Musik, Spaß, Mode und Unterhaltung.
            Aber HipHop.Dating ist viel mehr als das. Hip Hop, geboren am 11.
            August 1973 in der Bronx, New York, ist zur ersten wahren globalen
            Kultur geworden, die Menschen auf der ganzen Welt auf friedliche und
            zivilisierte Weise vereint.
            <br /> <br />
            Diese Kultur hat sich nicht durch Zwang entwickelt, wie einige
            historische Kulturen, die von religiösen, ethnischen oder
            militärischen Einflüssen angetrieben wurden. Stattdessen hat sich
            Hip Hop natürlich entwickelt und anfänglichen Widerstand von
            politischen, musikalischen und sozialen Institutionen überwunden.
            Seine Authentizität und solide Grundlage haben es ermöglicht, zu
            triumphieren und zur führenden kulturellen Kraft weltweit zu werden.
            <br /> <br />
            Heute geht Hip Hop über ein bloßes Musikgenre hinaus; es ist eine
            kulturelle Kraft, die Mode, Politik und mehr beeinflusst. Es bildet
            die Kernkulturidentität für Jugendliche und Ältere weltweit.
            <br /> <br />
            Unabhängig von deinem musikalischen Geschmack heißt dich
            HipHop.Dating willkommen zu einer Feier der Einheit, Liebe, des
            Friedens und der Harmonie. Hier kannst du in die Welt des Datings,
            der Musik, Mode, Unterhaltung und Kreativität eintauchen. Es ist
            eine Chance, nicht nur deine Individualität auszudrücken, sondern
            auch durch deine einzigartigen Ausdrucksformen Geld zu verdienen.
            Vereine dich mit uns in der dynamischen und vielfältigen Welt der
            Hip Hop-Kultur.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
