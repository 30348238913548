import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>Pourquoi et en quoi sommes-nous différents ?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              L'avantage de HipHop.Dating par rapport à de
              <br />
              nombreuses autres plateformes de rencontres <br />
              est que tous les utilisateurs ont déjà une passion <br />
              majeure commune dans la vie, qui est l'“Amour <br /> du Hip Hop et
              de sa culture”.
              <br />
              <br />
              D'autres plateformes permettent aux utilisateurs
              <br />
              de pré-sélectionner les correspondances potentielles
              <br />
              qu'ils voient, en filtrant les races, âges ou lieux
              <br />
              indésirables. Vous filtrez, vous indiquez “intéressé/ <br />
              pas intéressé”, vous répétez, etc. Certaines <br />
              applications utilisent des algorithmes pour choisir <br />
              qui vous montrer,et quand.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              Le manque d'intérêt commun de base, de passion et d'amour parmi
              les utilisateurs rend difficile pour de nombreuses applications de
              rencontres d'aider réalistement les utilisateurs à trouver leur
              âme sœur.
              <br />
              <br />
              Chez HipHop.Dating, les utilisateurs savent déjà qu'ils partagent
              le même intérêt, la même passion et le même amour pour le Hip Hop
              et sa culture (mode de vie, musique, mode, danse, poésie, arts,
              etc). Ils peuvent simplement discuter, sortir, partager et se
              découvrir naturellement.
              <br />
              <br />
              De plus, les Hip Hop Daters (utilisateurs) peuvent gagner de
              l'argent en créant et en publiant du contenu tout en s'amusant.
            </p>
            {/* <p>
              The lack of the basic common interest, passion <br /> and love
              among users renders it difficult for
              <br /> many dating apps to realistically help users to <br /> find
              their soulmates.
              <br />
              <br />
              At <b>HipHop.Dating</b>, users already know that they
              <br />
              share the same interest, passion and love for
              <br />
              Hip Hop and its culture (lifestyle, music, fashion,
              <br />
              dance, poetry, arts, etc). They can simply chat,
              <br />
              date, share and discover each other naturally.
              <br />
              <br />
              In addition, <b>Hip Hop Daters</b> (users) can make
              <br />
              money by creating and posting contents while having fun.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
