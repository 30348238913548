import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/French.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>est pour les vraies personnes, sans artifices !</h3>
          <p class="mid_text_p">
            Postez de courtes vidéos sur vous-même, parlez de vous, de vos
            visions, de vos hobbies, etc. <br /> Mettez en valeur vos talents et
            attirez votre rendez-vous et vos abonnés.
          </p>
          <p class="mid_text_p_mobile">
            Postez de courtes vidéos sur vous-même, parlez de vous, de vos
            visions, de vos hobbies, etc. Mettez en valeur vos talents et
            attirez votre rendez-vous et vos abonnés.
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
