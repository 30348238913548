import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">HipHop.Datingの素晴らしい点は何ですか？</h1>
          <p class="div-of-data-heading">
            「HipHop.Datingでは、ヒップホップデーター（つまりユーザー）
            は、単にデート、ビジネスの機会、キャリアアップ、新しい友情、
            またはこれらの2つまたは3つのオプションの組み合わせ、あるいは
            それらすべてに興味があるかどうかを選択できます。」
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              楽しみと冒険、仲間、⾃⼰発⾒、価値と愛されている感じ、
              個⼈的成⻑と⾃⼰理解、感情的サポート、安全とサポート、社会的
              拡⼤、共有活動、身体的および感情的親密さ、その他多くのこと。
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              コラボレーションとパートナーシップの機会、視
              認性の向上。あなたの価値観や⽂化に共鳴する個⼈とつながり、ビ
              ジョンを共有する⼈々と協⼒してください。そのようなつながり
              は、新しいパートナーシップや拡張の機会を求める個⼈または企業
              にとって⾮常に貴重です。
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              視認性の向上：コンテンツの投稿など、定期的な
              活動とエンゲージメントによって、パートナーのレーダー上に留ま
              ることができます。これにはレコードレーベル、映画プロデュー
              サー、ファッションブランド、⾳楽プロデューサー、出版社、マー
              ケティング代理店、広告主、および潜在的な候補者、才能、または
              協⼒者を探している業界の同僚が含まれます。
              <br />
              <br /> パーソナルブランディング：HipHop.Datingは、個⼈的なブランド
              または⾃分⾃身を世界規模で構築し、促進することを可能にしま す。
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              異なる⽂化的、社会的、または年齢層を超えた友情の
              構築は、偏⾒やステレオタイプを減らし、より包括的なマインド
              セットとすべての⼈のためのより良い世界を促進することができま す。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
