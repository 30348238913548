import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        Join us at HipHop.Dating and immerse yourself in <br />
        the vibrant Heart of the world’s global culture.
      </h1>
      <h1 className="nine_heding_mobile">
        Join us at HipHop.Dating and immerse yourself in the vibrant Heart of
        the world’s global culture.
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            We are a community that embraces unity and celebrates humanity{" "}
            <br />
            in its entirety. Here, you'll find a dynamic fusion of dating,
            music, fun,
            <br />
            fashion, and entertainment. But HipHop.Dating is much more than
            that. <br />
            Hip Hop, born on August 11, 1973, in Bronx, New York, has become the{" "}
            <br />
            first true global culture, uniting people across the world in a
            peaceful
            <br />
            and civilized way.
            <br /> <br />
            This culture has emerged not through force, like some historical
            cultures <br />
            driven by religious, ethnic, or military influences. Instead, Hip
            Hop has <br />
            naturally evolved, overcoming initial resistance from political,
            musical, <br />
            and social establishments. Its authenticity and solid foundation
            have
            <br />
            allowed it to triumph and become the leading cultural force
            globally.
            <br /> <br />
            Today, Hip Hop transcends being just a music genre; it's a cultural
            <br />
            powerhouse influencing fashion, politics, and more. It forms the
            <br />
            core cultural identity for youth and old worldwide.
            <br /> <br />
            No matter your musical taste, HipHop.Dating welcomes you to a <br />
            celebration of unity, love, peace, and harmony. Here, you can
            <br />
            dive into the world of dating, music, fashion, entertainment
            <br />
            and creativity. It's a chance not only to express your individuality
            <br />
            but also to earn money through your unique expressions. Unite with
            <br />
            us in the dynamic and diverse world of Hip Hop culture.
          </p>
          <p className="nine_pre_text_mobile">
            We are a community that embraces unity and celebrates humanity in
            its entirety. Here, you'll find a dynamic fusion of dating, music,
            fun, fashion, and entertainment. But HipHop.Dating is much more than
            that. Hip Hop, born on August 11, 1973, in Bronx, New York, has
            become the first true global culture, uniting people across the
            world in a peaceful and civilized way.
            <br /> <br />
            This culture has emerged not through force, like some historical
            cultures driven by religious, ethnic, or military influences.
            Instead, Hip Hop has naturally evolved, overcoming initial
            resistance from political, musical, and social establishments. Its
            authenticity and solid foundation have allowed it to triumph and
            become the leading cultural force globally.
            <br /> <br />
            Today, Hip Hop transcends being just a music genre; it's a cultural
            powerhouse influencing fashion, politics, and more. It forms the
            core cultural identity for youth and old worldwide.
            <br /> <br />
            No matter your musical taste, HipHop.Dating welcomes you to a
            celebration of unity, love, peace, and harmony. Here, you can dive
            into the world of dating, music, fashion, entertainment and
            creativity. It's a chance not only to express your individuality but
            also to earn money through your unique expressions. Unite with us in
            the dynamic and diverse world of Hip Hop culture.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
