import React from "react";
import "./sec7.css";
import sec7_img from "../../../../Assets/new_img_s.png";

const Section7 = () => {
  return (
    <div className="container5">
      <div className="fifth-sec">
        <div className="left-side-font">
          <h1>
            Inscription facile et <br />
            applications simples <br /> à utiliser
          </h1>
          <p>
            Votre solution unique pour les rencontres, <br /> le divertissement,
            la musique, le plaisir et plus encore.
          </p>
          <br />
          <br />
          <p className="pre">
            <b>Découvrez, Discutez, Partagez & Sortez.</b>
          </p>
        </div>
        <div className="right-side-img_nine">
          <img src={sec7_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
