import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">What is great about HipHop.Dating?</h1>
          <p class="div-of-data-heading">
            "At HipHop.Dating, hiphop daters (aka, users) can choose whether
            they are interested in just dating, business opportunities, career
            advancement, new friendships, or a combination of two or three
            options, or even all of them.”
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Fun and Adventure, Companionship, Self-Discovery, Feeling Valued
              and Loved, Personal Growth and Getting to Know Yourself, Emotional
              Support, Safety and Support, Social Expansion, Shared Activities,
              Physical and Emotional Intimacy, and more.
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Opportunities for Collaborations and Partnerships, Increasing
              Visibility. Connect with individuals who resonate with your values
              and culture, and collaborate with those who share your vision.
              Such connections can be invaluable for individuals or businesses
              seeking new partnerships or opportunities for expansion
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              Enhancing Your Visibility: Regular activity and engagement, such
              as posting content, can keep you on the radar of our partners.
              These include record labels, film producers, fashion brands, music
              producers, publishers, marketing agencies, and advertisers, as
              well as industry peers looking for potential candidates, talents,
              or collaborators.
              <br />
              <br /> Personal Branding: HipHop.Dating allows you to build and
              promote your personal brand or yourself on a global scale
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Building friendships across different cultural, social, or age
              groups can reduce prejudices and stereotypes, promoting a more
              inclusive mindset and a better world for all.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
