import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>À propos</h1>
      <p className="sec2_p_web">
        {" "}
        <b> HipHop.Dating</b> est une plateforme mondiale de rencontres et de
        divertissement social.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> est une plateforme mondiale de rencontres et de
        divertissement social.
      </p>
      <p className="pre-1">
        Postez de courtes vidéos sur qui vous êtes vraiment ! Soyez authentique,
        laissez votre rendez-vous vous accepter tel que vous êtes, <br />{" "}
        attirez plus d'abonnés et gagnez plus d'argent.
      </p>
      <p className="pre-1-mobile">
        Postez de courtes vidéos sur qui vous êtes vraiment !<br />
        Soyez authentique, laissez votre rendez-vous vous <br />
        accepter tel que vous êtes, attirez plus d'abonnés
        <br /> et gagnez plus d'argent.
      </p>

      <p className="pre-1">
        Mettez en valeur vos talents uniques, de la danse et de la mode au
        chant, au rap ou à la comédie, et captez l'attention <br />
        du monde. Utilisez cette plateforme pour briller et ouvrir les portes à
        de nouvelles opportunités.
      </p>
      <p className="pre-1-mobile">
        Mettez en valeur vos talents uniques, de la danse <br />
        et de la mode au chant, au rap ou à la comédie, et <br />
        captez l'attention du monde. Utilisez cette plateforme pour
        <br />
        briller et ouvrir les portes à de nouvelles opportunités.
      </p>
      <p className="pre-1">
        Oui, entrez dans le monde électrisant de <b>HipHop.Dating</b>, la
        destination ultime pour les célibataires et les personnes à <br />
        la recherche de plaisir, de connexion et d'un soupçon de showbiz !
        Plongez dans une expérience sociale exaltante <br />
        <b>
          <i>
            où vous n'êtes pas juste un autre profil, mais une étoile en devenir
          </i>
        </b>
        . Libérez votre vrai moi avec
        <br />
        notre fonctionnalité vidéo - une manière unique de vous présenter. Soyez
        audacieux, soyez authentique, <br />
        et laissez les correspondances potentielles tomber amoureuses de la
        personne que vous êtes vraiment.
      </p>
      <p className="pre-1-mobile">
        Oui, entrez dans le monde électrisant de <br />
        <b>HipHop.Dating</b>, la destination ultime pour les célibataires <br />
        et les personnes en quête de plaisir, de connexion et d'une <br />
        touche de le showbiz ! Plongez dans une expérience sociale
        <br /> exaltante{" "}
        <b>
          <i>
            où vous n'êtes pas juste un autre profil, mais <br /> une étoile en
            devenir
          </i>
        </b>
        . Libérez votre vrai moi <br />
        avec notre fonctionnalité vidéo - une manière unique de vous <br />
        présenter. Soyez audacieux, soyez authentique, et laissez les
        <br />
        correspondances potentielles tomber amoureuses de la <br />
        personne que vous êtes vraiment.
      </p>
      <p className="pre-1">
        Mais il y a plus ! Transformez votre parcours de rencontres en une
        célébration de vos talents. Que vous soyez <br /> un danseur groovy, une
        icône de la mode, un chanteur ou un rappeur mélodieux, ou que vous ayez
        un don <br />
        pour faire rire les gens,<b>HipHop.Dating</b> est votre scène. Montrez
        vos compétences spéciales, captivez un public
        <br /> d'admirateurs et regardez votre nombre d'abonnés grimper. Et
        devinez quoi ? Votre talent ne gagne pas seulement <br />
        des cœurs; il peut également devenir une avenue lucrative. Créez des
        connexions, gagnez des abonnés et augmentez <br />
        potentiellement vos gains, tout en profitant de l'excitation des
        rencontres et du réseautage social.
      </p>

      <p className="pre-1-mobile">
        Mais il y a plus ! Transformez votre parcours de <br />
        rencontres en une célébration de vos talents. Que <br />
        vous soyez un danseur groovy, une icône de la mode, <br />
        un chanteur ou un rappeur mélodieux, ou que vous ayez
        <br />
        un don pour faire rire les gens, <b>HipHop.Dating</b>
        <br />
        est votre scène. Montrez vos compétences spéciales, <br />
        captivez un public d'admirateurs et regardez votre
        <br />
        nombre d'abonnés grimper. Et devinez quoi ? Votre <br />
        talent ne gagne pas seulement des cœurs; il peut
        <br />
        également devenir une avenue lucrative. Créez des <br />
        connexions, gagnez des abonnés et augmentez <br />
        potentiellement vos gains, tout en profitant de <br />
        l'excitation des rencontres et du réseautage social.
        <br />
      </p>
      <p className="pre-1">
        Préparez-vous à faire partie d'une communauté dynamique où l'excitation
        ne s'arrête jamais et <br />
        chaque glissement peut mener à une nouvelle histoire incroyable.
        Rejoignez <b>HipHop.Dating</b> <br />
        aujourd'hui et commencez un voyage rempli de plaisir, de flair et de
        possibilités fantastiques !
      </p>
      <p className="pre-1-mobile">
        Préparez-vous à faire partie d'une communauté dynamique <br />
        où l'excitation ne s'arrête jamais et chaque glissement
        <br />
        peut mener à une nouvelle histoire incroyable. Rejoignez <br />
        <b>HipHop.Dating</b> aujourd'hui et commencez un voyage rempli <br />
        de plaisir, de flair et de possibilités fantastiques !
      </p>
    </div>
  );
};

export default Section2;
