import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>Acerca de</h1>
      <p className="sec2_p_web">
        {" "}
        <b> HipHop.Dating</b> es una plataforma global de citas y
        entretenimiento social.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> es una plataforma global de
        <br /> citas y entretenimiento social
      </p>
      <p className="pre-1">
        {" "}
        ¡Publica videos cortos sobre quién eres realmente! Sé auténtico, deja
        que tu cita te acepte tal como eres, <br />
        atrae más seguidores y gana más dinero.
      </p>
      <p className="pre-1-mobile">
        {" "}
        ¡Publica videos cortos sobre quién eres realmente! <br />
        Sé auténtico, deja que tu cita te acepte tal como eres, <br />
        atrae más seguidores y gana más dinero.
      </p>

      <p className="pre-1">
        Muestra tus talentos únicos, desde bailar y la moda hasta cantar, rapear
        o hacer comedia, y capta <br /> la atención del mundo. Usa esta
        plataforma para brillar y abrir puertas a nuevas oportunidades.
      </p>
      <p className="pre-1-mobile">
        Muestra tus talentos únicos, desde bailar y la moda <br />
        hasta cantar, rapear o hacer comedia, y capta la <br />
        atención del mundo. Usa esta plataforma para brillar <br />y abrir
        puertas a nuevas oportunidades.
      </p>
      <p className="pre-1">
        Sí, entra en el mundo electrizante de <b>HipHop.Dating</b>, el destino
        final para solteros y <br />
        people seeking fun, connection, and a dash of showbiz! Dive into an
        exhilarating social experience <br />
        <b>
          <i>
            donde no eres un perfil más, sino una estrella esperando brillar
          </i>
        </b>
        . Libera tu verdadero yo con <br />
        nuestra función de vídeo: una forma única de presentar tu verdadero yo.
        Sea audaz, sea genuino,
        <br />y deja que las posibles coincidencias se enamoren de la persona
        que realmente eres.
      </p>
      <p className="pre-1-mobile">
        Sí, entra en el mundo electrizante de
        <br />
        <b>HipHop.Dating</b>, el destino final para solteros y<br />
        people seeking fun, connection, and a dash of showbiz! <br />
        Dive into an exhilarating social experience
        <br />
        <b>
          <i>
            donde no eres un perfil más,
            <br />
            sino una estrella esperando brillar
          </i>
        </b>
        . <br />
        Libera tu verdadero yo con our video
        <br />
        Característica: una forma única de presentar tu verdadero yo.
        <br />
        Sé atrevida, sé genuina y deja coincidencias potenciales
        <br />
        Enamórate de la persona que realmente eres.
      </p>
      <p className="pre-1">
        ¡Pero hay más! Transforma tu viaje de citas en una celebración de tus
        talentos. Ya seas un bailarín
        <br />
        con ritmo, un icono de la moda, un cantante o rapero melódico, o tengas
        un don para hacer
        <br />
        reír a la gente, <b>HipHop.Dating </b> es tu escenario. Luce tus
        habilidades especiales, cautiva <br />
        a un público de admiradores y observa cómo aumenta tu número de
        seguidores. ¿Y sabes qué? <br />
        Tu talento no solo conquista corazones; también puede convertirse en un
        camino lucrativo. <br />
        Haz conexiones, gana seguidores y potencialmente aumenta tus ingresos,
        todo mientras <br />
        disfrutas del emocionante mundo de las citas y las redes sociales.
        <br />
      </p>

      <p className="pre-1-mobile">
        ¡Pero hay más! Transforma tu viaje de citas en una <br />
        celebración de tus talentos. Ya seas un bailarín con <br />
        ritmo, un icono de la moda, un cantante o rapero melódico, <br />o
        tengas un don para hacer reír a la gente, <b>HipHop.Dating</b> <br />
        es tu escenario. Luce tus habilidades especiales, cautiva a un <br />
        público de admiradores y observa cómo aumenta tu número de <br />
        seguidores. ¿Y sabes qué? Tu talento no solo conquista corazones; <br />
        también puede convertirse en un camino lucrativo. Haz conexiones, <br />
        gana seguidores y potencialmente aumenta tus ingresos, todo mientras{" "}
        <br />
        disfrutas del emocionante mundo de las citas y las redes sociales.
      </p>
      <p className="pre-1">
        Prepárate para ser parte de una comunidad vibrante donde la emoción
        nunca termina y cada <br />
        deslizamiento puede llevar a una increíble nueva historia. Únete a
        HipHop.Dating hoy y comienza <br />
        un viaje lleno de diversión, estilo y posibilidades fantásticas.
      </p>
      <p className="pre-1-mobile">
        Prepárate para ser parte de una comunidad vibrante
        <br />
        donde la emoción nunca termina y cada deslizamiento
        <br />
        puede llevar a una increíble nueva historia. Únete <br />
        a HipHop.Dating hoy y comienza un viaje lleno de <br />
        diversión, estilo y posibilidades fantásticas.
      </p>
    </div>
  );
};

export default Section2;
