import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>Por que e como somos diferentes?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              A vantagem do HipHop.Dating em comparação
              <br />
              com muitas outras plataformas de namoro é que todos
              <br />
              os usuários já têm uma grande paixão comum na
              <br />
              vida, que é o “Amor pelo Hip Hop e sua cultura”.
              <br />
              <br />
              Outras plataformas permitem que os usuários
              <br />
              pré-selecionem os potenciais parceiros que veem,
              <br />
              filtrando raças, idades ou localizações indesejáveis.
              <br /> Você filtra, indica “interessado/não,” repete, etc.
              <br /> Alguns aplicativos usam algoritmos para escolher
              <br /> quem mostrar para você, e quando.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              A falta do interesse comum básico, paixão
              <br /> e amor entre os usuários torna difícil para
              <br /> muitos aplicativos de namoro ajudarem <br />{" "}
              realisticamente os usuários a encontrar suas <br />
              almas gêmeas.
              <br />
              <br />
              No <b>HipHop.Dating</b>, os usuários já sabem que eles
              <br />
              compartilham o mesmo interesse, paixão e amor pelo
              <br />
              Hip Hop e sua cultura (estilo de vida, música, moda,
              <br />
              dança, poesia, artes, etc). Eles podem simplesmente
              <br />
              conversar, namorar, compartilhar e se descobrir <br />{" "}
              naturalmente.
              <br />
              <br />
              Além disso, os Namoradores de <b>Hip Hop Daters</b>
              <br />
              (usuários) podem ganhar dinheiro criando e <br />
              postando conteúdo enquanto se divertem.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
