import React, { useRef, useState, useMemo, useEffect } from "react";
import "./sec10.css";
import { useFormik } from "formik";
import Sec10_img from "../../../../Assets/contect_us.jpg";
import ReCAPTCHA from "react-google-recaptcha";
// import { toast } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { ContactusSchemas } from "../../../Schemas/index.jsx";
import axios from "../../../../comman/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Section10 = () => {
  const captchaRef = useRef(null);
  const [verified, setVerified] = useState(false);
  const [loading, setloading] = useState(false);
  const [recaptchaKey, setRecaptchaKey] = useState(Date.now());
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState();
  const [value, setValue] = useState();
  const initialValues = {
    FullName: "",
    LastName: "",
    Email: "",
    Number: "",
    Message: "",
  };
  const navigate = useNavigate();
  function onChange1() {
    setVerified(true);
  }

  //! without CAPTCHA api start
  // const onSubmit = async (values, { resetForm }) => {
  //   console.log("hello");
  //   setloading(true);
  //   try {
  //     const formData = new FormData();

  //     formData.append("FirstName", values.FullName);
  //     formData.append("LastName", values.LastName);
  //     formData.append("Email", values.Email);
  //     formData.append("PhoneNumber", values.Number);
  //     formData.append("Message", values.Message);

  //     // Make the POST request with formData
  //     const response = await axios.post("/contact_us", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data", // Make sure to set the content type to multipart/form-data
  //       },
  //     });
  //     console.log("check responce======>", response);
  //     setloading(false);
  //     if (response.data.status == 1) {
  //       resetForm();
  //       setValue();
  //       toast.success(`${response.data.message}`, { theme: "colored" });
  //       navigate("/ThankYou");
  //       console.log("🚀 ~ onSubmit ~ toast:", toast);
  //     }
  //   } catch (error) {
  //     setloading(false);
  //     console.log("error check ======>", error);
  //   }
  // };

  //! without CAPTCHA api end

  // ! with CAPTCHA API start

  const Pathname = useLocation();
  const checkPathname = Pathname.pathname;

  const onSubmit = async (values, { resetForm }) => {
    const recaptchaValue = captchaRef.current.getValue();

    if (recaptchaValue) {
      setloading(true);
      try {
        const formData = new FormData();

        formData.append("FirstName", values.FullName);
        formData.append("LastName", values.LastName);
        formData.append("Email", values.Email);
        formData.append("PhoneNumber", values.Number);
        formData.append("Message", values.Message);

        const response = await axios.post("/contact_us", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Make sure to set the content type to multipart/form-data
          },
        });
        setloading(false);
        if (response.data.status == 1) {
          setloading(false);
          setRecaptchaKey(Date.now());
          setVerified(false);
          setValue();
          resetForm();
          toast.success(`${response.data.message}`, { theme: "colored" });
          navigate("/Thankyou", {
            state: {
              checkPathname,
            },
          });
        }
      } catch (error) {
        setloading(false);
        console.error("Error:", error);
      }
    } else {
      console.error("ReCAPTCHA verification failed");
    }
  };
  // ! with CAPTCHA API End
  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: ContactusSchemas,
    onSubmit,
  });

  // useEffect(() => {
  //   if (value) {
  //     setFieldValue("Number", value);
  //   }
  // }, [value]);
  const user = (
    <svg
      width="16"
      height="21"
      viewBox="0 0 16 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 21C0 18.8783 0.842855 16.8434 2.34315 15.3431C3.84344 13.8429 5.87827 13 8 13C10.1217 13 12.1566 13.8429 13.6569 15.3431C15.1571 16.8434 16 18.8783 16 21H14C14 19.4087 13.3679 17.8826 12.2426 16.7574C11.1174 15.6321 9.5913 15 8 15C6.4087 15 4.88258 15.6321 3.75736 16.7574C2.63214 17.8826 2 19.4087 2 21H0ZM8 12C4.685 12 2 9.315 2 6C2 2.685 4.685 0 8 0C11.315 0 14 2.685 14 6C14 9.315 11.315 12 8 12ZM8 10C10.21 10 12 8.21 12 6C12 3.79 10.21 2 8 2C5.79 2 4 3.79 4 6C4 8.21 5.79 10 8 10Z"
        fill="#010101"
      />
    </svg>
  );
  const mail = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_84_37)">
        <path
          d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z"
          fill="#010101"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_37">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const mobile = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_84_47)">
        <path
          d="M7 4V20H17V4H7ZM6 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2ZM12 17C12.2652 17 12.5196 17.1054 12.7071 17.2929C12.8946 17.4804 13 17.7348 13 18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17Z"
          fill="#010101"
        />
      </g>
      <defs>
        <clipPath id="clip0_84_47">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  return (
    <>
      <div className="container8" id="Contact">
        <h1 data-aos="slide-down">Contact Us</h1>
        <div className="contect-sec">
          <form onSubmit={handleSubmit}>
            <div className="name-form">
              <div className="First-name">
                <div className="f-name">
                  <label htmlFor="FirstName">First Name</label>
                  <p>*</p>
                </div>
                <div className="name-box2">
                  <span>{user}</span>
                  {/* <img src={user} alt="" /> */}
                  <input
                    type="FullName"
                    name="FullName"
                    placeholder="FullName"
                    value={values.FullName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.FullName && touched.FullName ? (
                  <p className="errors_msg_p">{errors.FullName} </p>
                ) : null}
              </div>
              <div className="Last-name">
                <div className="l-name">
                  <label htmlFor="LastName">Last Name</label>
                  <p>*</p>
                </div>
                <div className="name-box2">
                  {/* <img src={user} alt="" /> */}
                  <span>{user}</span>
                  <input
                    type="text"
                    name="LastName"
                    placeholder="Last Name"
                    value={values.LastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.LastName && touched.LastName ? (
                  <p className="errors_msg_p">{errors.LastName} </p>
                ) : null}
              </div>
            </div>
            <div className="contect-form">
              <div className="Email">
                <div className="email">
                  <label htmlFor="Email">Email</label>
                  <p>*</p>
                </div>
                <div className="name-box2">
                  {/* <img src={mail} alt="" /> */}
                  <span>{mail}</span>
                  <input
                    type="email"
                    name="Email"
                    id="Email"
                    placeholder="abcd@xyz"
                    value={values.Email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.Email && touched.Email ? (
                  <p className="errors_msg_p">{errors.Email} </p>
                ) : null}
              </div>
              <div className="Mobile-number">
                <div className="M-number">
                  <label htmlFor="PhoneNumber">Mobile number</label>
                  <p>*</p>
                </div>
                <div className="name-box2">
                  {/* <img src={mobile} alt="" /> */}
                  <span>{mobile}</span>
                  <input
                    type="Number"
                    name="Number"
                    placeholder="98980 98980"
                    value={values.Number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.Number && touched.Number ? (
                  <p className="errors_msg_p">{errors.Number} </p>
                ) : null}
              </div>
            </div>
            <div className="Message">
              <div className="Message0">
                <label htmlFor="Message">
                  Message<p>*</p>
                </label>
                <div className="message1">
                  <textarea
                    name="Message"
                    type="Message"
                    placeholder="Write here"
                    value={values.Message}
                    onChange={handleChange}
                    onBlur={handleBlur}></textarea>
                </div>
                {errors.Message && touched.Message ? (
                  <p className="errors_msg_p ">{errors.Message} </p>
                ) : null}
              </div>
            </div>

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              ref={captchaRef}
              onChange={(value) => {
                onChange1();
                setSubmitButtonDisabled(!value);
              }}
            />

            <div className="submit">
              <input type="submit" value="SUBMIT" />
            </div>
          </form>
          <div className="right-side-img2">
            <h2>Let’s stay connected</h2>
            <p>
              If you have questions or special inquiries,
              <br />
              you're welcome to contact us
            </p>
            <img src={Sec10_img} alt="" />
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Section10;
