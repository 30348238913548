import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>Über</h1>
      <p className="sec2_p_web">
        {" "}
        <b> HipHop.Dating</b> ist eine globale Dating- und Social-Entertainment
        Networking-Plattform.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b>ist eine globale Dating- und <br />
        Social-Entertainment Networking-Plattform.
      </p>
      <p className="pre-1">
        {" "}
        Poste kurze Videos über dich selbst, sprich über dich, deine Visionen,
        deine Hobbys usw. <br /> Zeige dich, deine Talente und ziehe dein Date
        und Follower an.
      </p>
      <p className="pre-1-mobile-hi">
        {" "}
        Poste kurze Videos über dich selbst, sprich über dich, deine Visionen,
        deine Hobbys usw. Zeige dich, deine Talente und ziehe dein Date und
        Follower an.
      </p>

      <p className="pre-1">
        Zeige deine einzigartigen Talente, vom Tanzen und Mode über Singen,
        Rappen bis hin zu Comedy, und erreg <br /> die Aufmerksamkeit der Welt.
        Nutze diese Plattform, um zu glänzen und Türen zu neuen Möglichkeiten zu
        öffnen.
      </p>
      <p className="pre-1-mobile-hi">
        Zeige deine einzigartigen Talente, vom Tanzen und Mode über Singen,
        Rappen bis hin zu Comedy, und erreg die Aufmerksamkeit der Welt. Nutze
        diese Plattform, um zu glänzen und Türen zu neuen Möglichkeiten zu
        öffnen.
      </p>
      <p className="pre-1">
        Ja, tritt ein in die elektrisierende Welt von <b>HipHop.Dating</b>, t
        das ultimative Ziel für Singles und <br />
        Menschen, die Spaß, Verbindung und einen Hauch von Showbiz suchen!
        Tauche ein in ein aufregendes soziales Erlebnis, <br />
        <b>
          <i>
            bei dem du nicht nur ein weiteres Profil bist, sondern ein Stern,
            der darauf wartet, zu strahlen.
          </i>
        </b>
        . Entfessele dein wahres Ich mit <br />
        unserer Video-Funktion – eine einzigartige Möglichkeit, das echte Du
        vorzustellen. Sei mutig,
        <br />
        sei echt und lass potenzielle Matches sich in die Person verlieben, die
        du wirklich bist.
      </p>
      <p className="pre-1-mobile-hi">
        Ja, tritt ein in die elektrisierende Welt von <b>HipHop.Dating</b>, t
        das ultimative Ziel für Singles und Menschen, die Spaß, Verbindung und
        einen Hauch von Showbiz suchen! Tauche ein in ein aufregendes soziales
        Erlebnis,
        <b>
          <i>
            bei dem du nicht nur ein weiteres Profil bist, sondern ein Stern,
            der darauf wartet, zu strahlen.
          </i>
        </b>
        . Entfessele dein wahres Ich mit unserer Video-Funktion – eine
        einzigartige Möglichkeit, das echte Du vorzustellen. Sei mutig, sei echt
        und lass potenzielle Matches sich in die Person verlieben, die du
        wirklich bist.
      </p>
      <p className="pre-1">
        Aber es gibt noch mehr! Verwandle deine Dating-Reise in eine Feier
        deiner Talente. Ob du ein <br />
        grooviger Tänzer, ein Mode-Ikone, ein melodischer Sänger oder Rapper
        bist oder das Talent hast,
        <br />
        Menschen zum Lachen zu bringen, <b>HipHop.Dating</b> ist deine Bühne.
        Stelle deine besonderen Fähigkeiten zur
        <br />
        Schau, fasziniere ein Publikum von Bewunderern und beobachte, wie deine
        Follower Zahl in die Höhe schnellt.
        <br />
        Und rate mal was? Dein Flair gewinnt nicht nur Herzen; es kann auch zu
        einem lukrativen Weg werden. <br />
        Knüpfe Verbindungen, gewinne Follower und steigere möglicherweise deine
        Einnahmen, während du
        <br />
        den Nervenkitzel des Datings und des sozialen Netzwerkens genießt.
      </p>
      <p className="pre-1-mobile-hi">
        Aber es gibt noch mehr! Verwandle deine Dating-Reise in eine Feier
        deiner Talente. Ob du ein grooviger Tänzer, ein Mode-Ikone, ein
        melodischer Sänger oder Rapper bist oder das Talent hast, Menschen zum
        Lachen zu bringen, <b>HipHop.Dating</b> ist deine Bühne. Stelle deine
        besonderen Fähigkeiten zur Schau, fasziniere ein Publikum von
        Bewunderern und beobachte, wie deine Follower Zahl in die Höhe schnellt.
        Und rate mal was? Dein Flair gewinnt nicht nur Herzen; es kann auch zu
        einem lukrativen Weg werden. Knüpfe Verbindungen, gewinne Follower und
        steigere möglicherweise deine Einnahmen, während du den Nervenkitzel des
        Datings und des sozialen Netzwerkens genießt.
      </p>
      <p className="pre-1">
        Mach dich bereit, Teil einer lebendigen Gemeinschaft zu sein, wo die
        Aufregung nie endet und jeder Swipe zu
        <br />
        einer erstaunlichen neuen Geschichte führen könnte. Tritt heute{" "}
        <b>HipHop.Dating</b> bei und beginne eine Reise voller Spaß,
        <br /> Flair und fantastischer Möglichkeiten!
        <br />
      </p>

      <p className="pre-1-mobile-hi">
        Mach dich bereit, Teil einer lebendigen Gemeinschaft zu sein, wo die
        Aufregung nie endet und jeder Swipe zu einer erstaunlichen neuen
        Geschichte führen könnte. Tritt heute <b>HipHop.Dating</b> bei und
        beginne eine Reise voller Spaß, Flair und fantastischer Möglichkeiten!
      </p>
    </div>
  );
};

export default Section2;
