import React from "react";
import "./sec1.css";
import applestore from "../../../../Assets/iOS_btn.png";
import playstore from "../../../../Assets/play_store_btn.png";
import sec1img from "../../../../Assets/header_img.png";
const Section1 = () => {
  return (
    <div className="head-sec" id="Sec1">
      <div className="cont-1">
        <div className="text-area">
          <h1 className="R_h1">
            Это нечто большее, чем просто свидания<span>&trade;</span>
          </h1>
          <p className="R_p">
            Это праздник любви, жизни, <br />
            музыки, веселья и моды, а<br />
            также возможность зарабатывать <br />в том же процессе.
          </p>
          <div className="img2">
            <p>Скоро будет доступно!</p>
            <div className="box-1">
              <img src={applestore} alt="" />
              <img src={playstore} alt="" />
            </div>
          </div>
        </div>
        <div className="header-img">
          <img src={sec1img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
