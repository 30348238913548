import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        Rejoignez-nous chez HipHop.Dating et plongez-vous <br />
        dans le cœur vibrant de la culture mondiale.
      </h1>
      <h1 className="nine_heding_mobile">
        Rejoignez-nous chez HipHop.Dating et plongez-vous dans le cœur vibrant
        de la culture mondiale.
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            Nous sommes une communauté qui embrasse l'unité et célèbre
            l'humanité <br />
            dans son intégralité. Ici, vous trouverez une fusion dynamique de
            rencontres,
            <br />
            de musique, de plaisir, de mode et de divertissement. Mais
            HipHop.Dating
            <br />
            est bien plus que cela. Le Hip Hop, né le 11 août 1973 dans le
            Bronx, à
            <br />
            New York, est devenu la première véritable culture mondiale,
            unissant
            <br />
            les gens du monde entier de manière pacifique et civilisée.
            <br />
            <br />
            Cette culture n'a pas émergé par la force, comme certaines cultures
            <br />
            historiques entraînées par des influences religieuses, ethniques ou
            <br />
            militaires. Au contraire, le Hip Hop a évolué naturellement,
            surmontant <br />
            la résistance initiale des établissements politiques, musicaux et
            <br />
            sociaux. Son authenticité et sa base solide lui ont permis de
            triompher
            <br />
            et de devenir la force culturelle dominante à l'échelle mondiale.
            <br />
            <br />
            Aujourd'hui, le Hip Hop transcende le fait d'être juste un genre
            musical ; <br />
            c'est une puissance culturelle influençant la mode, la politique et
            plus <br />
            encore. Il forme l'identité culturelle de base pour les jeunes et
            les
            <br />
            moins jeunes dans le monde entier.
            <br />
            <br />
            Peu importe vos goûts musicaux, HipHop.Dating vous accueille dans
            une <br />
            célébration de l'unité, de l'amour, de la paix et de l'harmonie.
            Ici, vous <br /> pouvez plonger dans le monde des rencontres, de la
            musique, de la mode, <br />
            du divertissement et de la créativité. C'est une chance non
            seulement
            <br />
            d'exprimer votre individualité, mais aussi de gagner de l'argent
            grâce à
            <br />
            vos expressions uniques. Unissez-vous à nous dans le monde dynamique
            <br />
            et diversifié de la culture Hip Hop.
          </p>
          <p className="nine_pre_text_mobile">
            Nous sommes une communauté qui embrasse l'unité et célèbre
            l'humanité dans son intégralité. Ici, vous trouverez une fusion
            dynamique de rencontres, de musique, de plaisir, de mode et de
            divertissement. Mais HipHop.Dating est bien plus que cela. Le Hip
            Hop, né le 11 août 1973 dans le Bronx, à New York, est devenu la
            première véritable culture mondiale, unissant les gens du monde
            entier de manière pacifique et civilisée.
            <br /> <br />
            Cette culture n'a pas émergé par la force, comme certaines cultures
            historiques entraînées par des influences religieuses, ethniques ou
            militaires. Au contraire, le Hip Hop a évolué naturellement,
            surmontant la résistance initiale des établissements politiques,
            musicaux et sociaux. Son authenticité et sa base solide lui ont
            permis de triompher et de devenir la force culturelle dominante à
            l'échelle mondiale.
            <br /> <br />
            Aujourd'hui, le Hip Hop transcende le fait d'être juste un genre
            musical ; c'est une puissance culturelle influençant la mode, la
            politique et plus encore. Il forme l'identité culturelle de base
            pour les jeunes et les moins jeunes dans le monde entier
            <br /> <br />
            Peu importe vos goûts musicaux, HipHop.Dating vous accueille dans
            une célébration de l'unité, de l'amour, de la paix et de l'harmonie.
            Ici, vous pouvez plonger dans le monde des rencontres, de la
            musique, de la mode, du divertissement et de la créativité. C'est
            une chance non seulement d'exprimer votre individualité, mais aussi
            de gagner de l'argent grâce à vos expressions uniques. Unissez-vous
            à nous dans le monde dynamique et diversifié de la culture Hip Hop.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
