import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        انضم إلينا في HipHop.Dating وانغمس في <br />
        القلب النابض للثقافة العالمية في العالم.
      </h1>
      <h1 className="nine_heding_mobile">
        انضم إلينا في HipHop.Dating وانغمس في ذلك القلب النابض للثقافة العالمية
        في العالم.
      </h1>
      <div className="nine-sec-gr">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine_gr">
          <p className="nine_pre_text_AR">
            نحن مجتمع يعانق الوحدة ويحتفل بالنسانية بأكملها. هنا، ستجد توليفة
            ديناميكية من أكثر من ذلك بكثير. HipHop.Dating الواعدة، الوسيقى،
            الرح، الزياء والترفيه. لكن الهيب هوب، الذي وُلِد في 11 أغسطس 1973 في
            برونكس، نيويورك، أصبح أول ثقافة .عالية حقيقية، توحد الناس في جميع
            أنحاء العالم بطريقة سلمية وحضارية
            <br /> <br />
            هذه الثقافة لم تظهر من خلل القوة، مثل بعض الثقافات التاريخية التي
            تدفعها التأثيرات الدينية أو العرقية أو العسكرية. بل تطورت الهيب هوب
            بشكل طبيعي، متغلبةً على القاومة الولية من الؤسسات السياسية والوسيقية
            والجتماعية. جعلت أصالتها وأساسها الراسخ .منها تنتصر وتصبح القوة
            الثقافية الرائدة عاليًا
            <br /> <br />
            اليوم، الهيب هوب يتجاوز كونه مجرد نوع موسيقي؛ إنه قوة ثقافية تؤثر
            على الوضة والسياسة وأكثر. إنه يشكل الهوية الثقافية الساسية للشباب
            والكبار في جميع أنحاء .العالم
            <br /> <br />
            في احتفال بالوحدة والحب HipHop.Dating بغض النظر عن ذوقك الوسيقي،
            يرحب بك والسلم والنسجام. هنا، يمكنك الغوص في عالم الواعدة والوسيقى
            والزياء والترفيه والبداع. إنها فرصة ليس فقط للتعبير عن فرديتك ولكن
            أيض ً ا لكسب الال من خلل .تعبيراتك الفريدة. اتحد معنا في عالم
            الثقافة الهيب هوب الديناميكي والتنوع
          </p>
          <p className="nine_pre_text_AR_mobile">
            نحن مجتمع يعانق الوحدة ويحتفل بالنسانية بأكملها. هنا، ستجد توليفة
            ديناميكية من أكثر من ذلك بكثير. HipHop.Dating الواعدة، الوسيقى،
            الرح، الزياء والترفيه. لكن الهيب هوب، الذي وُلِد في 11 أغسطس 1973 في
            برونكس، نيويورك، أصبح أول ثقافة .عالية حقيقية، توحد الناس في جميع
            أنحاء العالم بطريقة سلمية وحضارية
            <br /> <br />
            هذه الثقافة لم تظهر من خلل القوة، مثل بعض الثقافات التاريخية التي
            تدفعها التأثيرات الدينية أو العرقية أو العسكرية. بل تطورت الهيب هوب
            بشكل طبيعي، متغلبةً على القاومة الولية من الؤسسات السياسية والوسيقية
            والجتماعية. جعلت أصالتها وأساسها الراسخ .منها تنتصر وتصبح القوة
            الثقافية الرائدة عاليًا
            <br /> <br />
            اليوم، الهيب هوب يتجاوز كونه مجرد نوع موسيقي؛ إنه قوة ثقافية تؤثر
            على الوضة والسياسة وأكثر. إنه يشكل الهوية الثقافية الساسية للشباب
            والكبار في جميع أنحاء .العالم
            <br /> <br />
            في احتفال بالوحدة والحب HipHop.Dating بغض النظر عن ذوقك الوسيقي،
            يرحب بك والسلم والنسجام. هنا، يمكنك الغوص في عالم الواعدة والوسيقى
            والزياء والترفيه والبداع. إنها فرصة ليس فقط للتعبير عن فرديتك ولكن
            أيض ً ا لكسب الال من خلل .تعبيراتك الفريدة. اتحد معنا في عالم
            الثقافة الهيب هوب الديناميكي والتنوع
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
