import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        HipHop.Datingに参加して、世界のグローバルカルチ
        <br />
        ャー の活気あ る中⼼に没頭しましょう。
      </h1>
      <h1 className="nine_heding_mobile">
        HipHop.Datingに参加して、世界のグローバルカルチャーの活気あ
        る中⼼に没頭しましょう。
      </h1>
      <div className="nine-sec-gr">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine_gr">
          <p className="nine_pre_text">
            私たちは統⼀を受け⼊れ、⼈類全体を祝うコミュニティです。ここ
            では、デート、⾳楽、楽しみ、ファッション、エンターテイメント
            のダイナミックな融合を⾒つけることができます。しかし
            HipHop.Datingはそれ以上のものです。1973年8⽉11⽇、ニュー
            ヨークのブロンクスで誕⽣したヒップホップは、世界中の⼈々を平
            和で⽂明的な⽅法で結びつける、最初の真のグローバルカルチャー
            となりました。
            <br /> <br />
            この⽂化は、宗教的、⺠族的、軍事的影響によって推進された歴史
            上のいくつかの⽂化のように⼒によってではなく、⾃然に進化しま
            した。ヒップホップは、政治的、⾳楽的、社会的確⽴に対する初期
            の抵抗を克服しました。その真実性としっかりとした基盤が、それ
            を勝利に導き、世界的な⽂化的な⼒としての地位を築くことを可能
            にしました。
            <br /> <br />
            今⽇、ヒップホップは単なる⾳楽ジャンルであるにとどまらず、
            ファッション、政治などに影響を与える⽂化的なパワーハウスで
            す。それは世界中の若者と⽼⼈の核となる⽂化的アイデンティティ
            を形成しています。
            <br /> <br />
            あなたの⾳楽の好みにかかわらず、HipHop.Datingはあなたを統
            ⼀、愛、平和、調和のお祝いに歓迎します。ここでは、デート、⾳
            楽、ファッション、エンターテイメント、創造性の世界に⾶び込む
            ことができます。それはあなたの個性を表現するだけでなく、あな
            たのユニークな表現を通じてお⾦を稼ぐ機会でもあります。ヒップ
            ホップ⽂化のダイナミックで多様な世界で私たちと⼀緒に統合しま
            しょう。
          </p>
          <p className="nine_pre_text_mobile">
            私たちは統⼀を受け⼊れ、⼈類全体を祝うコミュニティです。ここ
            では、デート、⾳楽、楽しみ、ファッション、エンターテイメント
            のダイナミックな融合を⾒つけることができます。しかし
            HipHop.Datingはそれ以上のものです。1973年8⽉11⽇、ニュー
            ヨークのブロンクスで誕⽣したヒップホップは、世界中の⼈々を平
            和で⽂明的な⽅法で結びつける、最初の真のグローバルカルチャー
            となりました。
            <br /> <br />
            この⽂化は、宗教的、⺠族的、軍事的影響によって推進された歴史
            上のいくつかの⽂化のように⼒によってではなく、⾃然に進化しま
            した。ヒップホップは、政治的、⾳楽的、社会的確⽴に対する初期
            の抵抗を克服しました。その真実性としっかりとした基盤が、それ
            を勝利に導き、世界的な⽂化的な⼒としての地位を築くことを可能
            にしました。
            <br /> <br />
            今⽇、ヒップホップは単なる⾳楽ジャンルであるにとどまらず、
            ファッション、政治などに影響を与える⽂化的なパワーハウスで
            す。それは世界中の若者と⽼⼈の核となる⽂化的アイデンティティ
            を形成しています。
            <br /> <br />
            あなたの⾳楽の好みにかかわらず、HipHop.Datingはあなたを統
            ⼀、愛、平和、調和のお祝いに歓迎します。ここでは、デート、⾳
            楽、ファッション、エンターテイメント、創造性の世界に⾶び込む
            ことができます。それはあなたの個性を表現するだけでなく、あな
            たのユニークな表現を通じてお⾦を稼ぐ機会でもあります。ヒップ
            ホップ⽂化のダイナミックで多様な世界で私たちと⼀緒に統合しま
            しょう。
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
