import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>Почему и в чем наше отличие?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              Преимущество HipHop.Dating по сравнению с <br />
              многими другими платформами знакомств <br />
              заключается в том, что все пользователи <br />
              уже разделяют общую главную страсть в жизни, <br />
              которая является «Любовью к хип-хопу и его <br />
              культуре».
              <br />
              <br />
              Другие платформы позволяют пользователям <br />
              предварительно отфильтровывать потенциальных <br />
              партнеров, исключая нежелательные расы,
              <br />
              возрасты или местоположения. Вы фильтруете, <br />
              указываете "интересует/не интересует", <br />
              повторяете и т.д. Некоторые приложения <br /> используют алгоритмы
              для выбора, кого вам <br />
              показывать и когда.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              Отсутствие базового общего интереса, страсти <br />
              и любви среди пользователей затрудняет для <br />
              многих приложений знакомств реалистичную
              <br />
              помощь пользователям в поиске их второй <br /> половинки.
              <br />
              <br />
              Dating пользователи уже знают, что они разделяют <br />
              одинаковый интерес, страсть и любовь к хип-хопу <br />
              и его культуре (образу жизни, музыке, моде, танцам, <br />
              поэзии, т.д.).s искусствам и Они могут просто <br />
              общаться, встречаться, делиться и <br />
              естественным образом открывать друг друга.
              <br />
              <br />
              Кроме того, пользователи <b>Hip Hop Daters</b> могут <br />
              зарабатывать деньги, создавая и размещая контент, <br /> получая
              удовольствие.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
