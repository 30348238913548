import "./App.css";
import { Route, Routes } from "react-router-dom";
import LandingPage from "./page/Landingpage/index.jsx";
import Trems from "./page/Trems/index.jsx";
import Privacy from "./page/Privacy/index.jsx";
import Safety from "./page/Sefety/index.jsx";
import Thankyou from "./page/Thankyou";
import LandingpageFR from "./page/LandingpageFR/index.jsx";
import LandingpageSP from "./page/LandingpageSP/index.jsx";
import LandingpageHI from "./page/LandingpageHI/index.jsx";
import LandingpageCH from "./page/LandingpageCH/index.jsx";
import LandingpageSW from "./page/LandingpageSW/index.jsx";
import LandingpagePT from "./page/LandingpagePT/index.jsx";
import LandingpageRS from "./page/LandingpageRS/index.jsx";
import LandingpageGR from "./page/LandingpageGR/index.jsx";
import LandingpageJP from "./page/LandingpageJP/index.jsx";
import LandingpageAR from "./page/LandingpageAR/index.jsx";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/French" element={<LandingpageFR />} />
        <Route path="/Spanish" element={<LandingpageSP />} />
        <Route path="/Hindi" element={<LandingpageHI />} />
        <Route path="/Chinese" element={<LandingpageCH />} />
        <Route path="/Swahili" element={<LandingpageSW />} />
        <Route path="/Portuguese" element={<LandingpagePT />} />
        <Route path="/Russian" element={<LandingpageRS />} />
        <Route path="/German" element={<LandingpageGR />} />
        <Route path="/Japanese" element={<LandingpageJP />} />
        <Route path="/Arabic" element={<LandingpageAR />} />
        <Route path="/Terms-of-Use" element={<Trems />} />
        <Route path="/Privacy-policy-&-Cookies" element={<Privacy />} />
        <Route path="/Safety-&-Security" element={<Safety />} />
        <Route path="/ThankYou" element={<Thankyou />} />
      </Routes>
    </div>
  );
}

export default App;
