import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>About</h1>
      <p className="sec2_p_web">
        {" "}
        <b> HipHop.Dating</b> is a global dating and social entertainment
        networking platform.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> is a global dating and <br /> social entertainment
        networking platform.
      </p>
      <p className="pre-1">
        {" "}
        Post short videos about who you really are! Be authentic, let's your
        date accept you as you are,
        <br />
        attract more followers, and make more money.
      </p>
      <p className="pre-1-mobile">
        {" "}
        Post short videos about who you really are! <br />
        Be authentic, let's your date accept you as you are,
        <br />
        attract more followers, and make more money.
      </p>

      <p className="pre-1">
        Showcase your unique talents, from dance and fashion to singing,
        rapping, or comedy, and grab <br />
        the world's attention. Use this platform to shine and open doors to new
        opportunities.
      </p>
      <p className="pre-1-mobile">
        Showcase yourself, your dancing skills, your fashion
        <br />
        skills, your singing and/or rapping skills, your
        <br />
        comedian skills or whatever skills you possess <br />
        and get discovered!
      </p>
      <p className="pre-1">
        Yes, step into the electrifying world of <b>HipHop.Dating</b>, the
        ultimate destination for singles and <br />
        people seeking fun, connection, and a dash of showbiz! Dive into an
        exhilarating social experience <br />
        <b>
          <i>
            where you're not just another profile, but a star waiting to shine
          </i>
        </b>
        . Unleash your true self with <br />
        our video feature — a unique way to introduce the real you. Be bold, be
        genuine,
        <br />
        and let potential matches fall for the person you truly are.
      </p>
      <p className="pre-1">
        But there's more! Transform your dating journey into a celebr/ation of
        your talents. Whether you're <br />
        a groovy dancer, a fashion icon, a melodious singer or rapper, or have a
        knack for making people <br />
        laugh, <b>HipHop.Dating</b> is your stage. Flaunt your special skills,
        captivate an audience of admirers,
        <br />
        and watch your follower count soar. And guess what? Your flair doesn't
        just win hearts; it can also <br />
        turn into a lucrative avenue. Make connections, earn followers, and
        potentially boost your <br />
        earnings, all while enjoying the thrill of dating and social networking.{" "}
        <br />
      </p>
      <p className="pre-1">
        Get ready to be part of a vibr/ant community where excitement never ends
        and every swipe could <br />
        lead to an amazing new story. Join <b>HipHop.Dating</b> today and start
        a journey filled with fun, flair,
        <br />
        and fantastic possibilities!
      </p>
      <p className="pre-1-mobile">
        Yes, step into the electrifying world of <br />
        <b>HipHop.Dating</b>, the ultimate destination for singles <br />
        and people seeking fun, connection, and a dash of <br />
        showbiz! Dive into an exhilarating social experience <br />
        <b>
          <i>
            where you're not just another profile,
            <br />
            but a star waiting to shine
          </i>
        </b>
        . <br />
        Unleash your true self with our video
        <br />
        feature — a unique way to introduce the real you.
        <br />
        Be bold, be genuine,and let potential matches <br />
        fall for the person you truly are.
      </p>
      <p className="pre-1-mobile">
        But there's more! Transform your dating journey <br />
        into a celebr/ation of your talents. Whether you're <br />
        a groovy dancer, a fashion icon, a melodious singer
        <br />
        or rapper, or have a knack for making people laugh,
        <br />
        <b>HipHop.Dating</b> is your stage. Flaunt your <br />
        special skills, captivate an audience of admirers,
        <br />
        and watch your follower count soar. And guess what? <br />
        Your flair doesn't just win hearts; it can also <br />
        turn into a lucrative avenue. Make connections,
        <br />
        earn followers, and potentially boost your <br />
        earnings, all while enjoying the thrill of
        <br />
        dating and social networking. <br />
      </p>
      <p className="pre-1-mobile">
        Get ready to be part of a vibr/ant community where <br />
        excitement never ends and every swipe could <br />
        lead to an amazing new story. Join <b>HipHop.Dating</b> <br />
        today and start a journey filled with fun, flair,
        <br />
        and fantastic possibilities!
      </p>
    </div>
  );
};

export default Section2;
