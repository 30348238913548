import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>紹介</h1>
      <p className="sec2_p_web">
        {" "}
        <b>
          {" "}
          HipHop.Dating
        </b> は、世界的なデートおよびソーシャルエンターテイ <br />
        メントネットワーキングプラットフォームです。
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> は、世界的なデートおよびソー <br />
        シャルエンタ ーテイ メントネットワーキングプラットフォームです。
      </p>
      <p className="pre-1">
        {" "}
        本当の自分についての短いビデオを投稿してください! 本物でありましょう。
        ありのままのあなたを受け入れてくれるデート、
        <br />
        より多くのフォロワーを集め、より多くのお金を稼ぎます。
      </p>
      <p className="pre-1-mobile">
        {" "}
        本当の自分についての短いビデオを投稿してください! <br />{" "}
        本物でありましょう。 ありのままのあなたを受け入れてくれるデート、
        <br />
        より多くのフォロワーを集め、より多くのお金を稼ぎます。
      </p>

      <p className="pre-1">
        ダンス、ファッション、歌まであなたのユニークな才能を発揮し、
        ラップとかコメディとかグラブ
        <br /> とか。 世界の注目。
        このプラットフォームを使用して輝き、新たな世界への扉を開きます
        チャンス。
      </p>
      <p className="pre-1-mobile">
        自分自身、ダンススキル、ファッションを披露しましょう
        <br />
        スキル、歌やラップのスキル、
        <br />
        コメディアンのスキルやあなたが持っているスキル
        <br /> など。 そして発見されましょう！
      </p>
      <p className="pre-1">
        はい、<b>ヒップホップ デート</b>{" "}
        の刺激的な世界に足を踏み入れてください。 独身者にとっての究極の目的地と
        <br />
        楽しみ、つながり、そしてちょっとしたショービジネスを求めている人たち！
        ダイブ 爽快な社会体験
        <br />
        <b>
          <i>
            そこではあなたは単なるプロフィールではなく、輝くのを待っているスターです
          </i>
        </b>
        。 本当の自分を
        <br /> 解き放ちましょう。 私たちのビデオ機能 —
        本当のあなたを紹介するユニークな方法です。 大胆に、大胆に 本物、
        <br />
        そして、潜在的なマッチがあなた自身に当てはまるようにしてください。
      </p>
      <p className="pre-1-mobile">
        そう、感動の
        <br /> 世界へ足を踏み入れましょう。
        <b>HipHop.Dating</b> は独身者のための究極の目的地です。
        <br />
        楽しみ、つながり、そしてちょっとした
        <br /> ことを求める人々 芸能界！ 爽快なソーシャル体験に飛び込みましょう
        <br />
        <b>
          <i>
            あなたは単なる別のプロフィールではなく、
            <br />
            でも輝くのを待っている星
          </i>
        </b>
        。 <br />
        私たちのビデオで本当の自分を解き放ちましょう
        <br />
        特集 — 本当のあなたを紹介するユニークな方法。
        <br />
        大胆に、誠実に、潜在的なマッチングを受け入れてください
        <br />
        本当の自分に恋をしてください。
      </p>
      <p className="pre-1">
        しかし、それだけではありません! あなたのデートの旅をお祝いに変えましょう
        あなたの才能。 あなたが
        <br /> いるかどうか。
        グルービーなダンサー、ファッションアイコン、メロディアスな歌手やラッパー、あるいは
        人を作るコツ
        <br />
        笑ってください、<b>ヒップホップ デート</b> があなたの舞台です。
        あなたの特技を披露し、 多くのファンを魅了し、
        <br />
        フォロワー数が急増するのを見てください。 そして、何だと思いますか？
        あなたの才能はそうではありません <br />
        ただ心を勝ち取るだけです。 それも 可能です 儲かる道に変わります。
        つながりを作り、フォロワーを獲得し、 あなたの
        <br /> 能力を高める可能性があります デートやソーシャル
        ネットワーキングのスリルを楽しみながら、収入を得ることができます。{" "}
        <br />
      </p>
      <p className="pre-1-mobile">
        しかし、それだけではありません! デートの旅を変えましょう
        <br />
        あなたの才能を讃えましょう。 あなたが
        <br /> いるかどうか。
        グルービーなダンサー、ファッションアイコン、メロディアスな歌手
        <br />
        またはラッパー、または人々を笑わせる才能がある、
        <br />
        <b>HipHop.Dating</b> があなたのステージです。 あなたの
        <br /> を誇示します。 特殊なスキルで多くのファンを魅了し、
        <br />
        フォロワー数が急増するのを見てください。 そして、何だと思いますか？{" "}
        <br />
        あなたのセンスは心をつかむだけではありません。 それも
        <br /> 可能です 儲かる道に変わります。 つながりを作り、
        <br />
        フォロワーを獲得し、あなたの
        <br /> 価値を高める可能性があります。 スリルを楽しみながら収入を得る
        <br />
        デートとソーシャルネットワーキング。 <br />
      </p>
      <p className="pre-1">
        興奮が止まらない、活気に満ちたコミュニティに参加する準備をしましょう
        スワイプするたびに
        <br />
        驚くべき新たな物語へ導きます。 今すぐ <b>HipHop.Dating</b>{" "}
        に参加して始めましょう 楽しさ、センスに満ちた旅、
        <br />
        そして素晴らしい可能性！
      </p>

      <p className="pre-1-mobile">
        活気に満ちたコミュニティの一員になる準備をしましょう。
        <br />
        興奮は決して終わることがなく、スワイプするたびに
        <br />
        驚くべき新たな物語へとつながります。 <b>ヒップホップ デート</b>{" "}
        に参加してください。
        <br />
        今日、楽しさとセンスに満ちた旅を始めましょう
        <br />
        そして素晴らしい可能性！
      </p>
    </div>
  );
};

export default Section2;
