import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/Swahili.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>ni kwa watu halisi, hakuna hila!</h3>
          <p class="mid_text_p">
            Chapisha video fupi kuhusu wewe mwenyewe, zungumza kuhusu wewe,
            maono yako, burudani zako, n.k.
            <br /> Onyesha wewe mwenyewe, vipaji vyako na vutia mtu wako wa
            kutongoza na wafuasi.
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            Chapisha video fupi kuhusu wewe mwenyewe, zungumza kuhusu wewe,
            maono yako, burudani zako, n.k. Onyesha wewe mwenyewe, vipaji vyako
            na vutia mtu wako wa kutongoza na wafuasi.
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
