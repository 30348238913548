import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/Spanish.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>es para la gente real, ¡sin trucos!</h3>
          <p class="mid_text_p">
            Publica videos cortos sobre ti mismo, habla de ti, tus visiones, tus
            pasatiempos, etc. <br /> Muestra lo que eres, tus talentos y atrae a
            tu cita y seguidores
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            Publica videos cortos sobre ti mismo, habla de ti, tus visiones, tus
            pasatiempos, etc. Muestra lo que eres, tus talentos y atrae a tu
            cita y seguidores
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
