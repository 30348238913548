import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/circle_img.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>is for the real people, no gimmicks!</h3>
          <p class="mid_text_p">
            Post short videos about yourself, talk about you, your visions, your
            hobbies, etc.
            <br /> Showcase yourself, your talents and attract your date and
            followers.
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            Post short videos about yourself, talk about you, your visions, your
            hobbies, etc. Showcase yourself, your talents and attract your date
            and followers.
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
