import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>हम अलग कै सेहैंऔर क्यों?</h1>
        </div>
        <div class="main-conten" style={{ width: "90%" }}>
          <div class="left-side-text">
            <p>
              HipHop.Dating की अन्य डेटिंग प्लेटफॉर्म्सके मुकाबलेएक बड़ी खासियत
              यह है कि सभी यूजर्सके पास पहलेसेही जीवन मेंएक सामान्य बड़ा जुनून
              होता हैजो है “हिप हॉप और उसकी संस्कृति के प्रति प्रेम।”
              <br />
              <br />
              अन्य प्लेटफॉर्म्सयूजर्सको संभावित मैचों को पहलेसेछांटनेकी अनुमति
              देतेहैं, जिसमें अवांछित जातियों, उम्र, या स्थानों को फिल्टर किया
              जाता है। आप फिल्टर करतेहैं, "रुचि है/नहीं है" इंगित करतेहैं, और
              इसेदोहरातेहैंआदि। कु छ ऐप्स एल्गोरिदम का उपयोग करतेहैंजो आपको
              दिखाए जानेवालेलोगों का चयन करतेहैं, और कब।
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              मूल सामान्य रुचि, जुनून और प्रेम की कमी के कारण कई डेटिंग ऐप्स के
              लिए यथार्थता मेंयूजर्सको उनके आत्मीय साथी खोजनेमेंमदद करना मुश्किल
              होता है।
              <br />
              <br />
              <b>HipHop.Dating </b>में, यूजर्सपहलेसेही जानतेहैंकि वेहिप हॉप और
              उसकी संस्कृति (जीवन शैली, संगीत, फै शन, नृत्य, कविता, कला, आदि) के
              प्रति सामान्य रुचि, जुनून और प्रेम साझा करतेहैं। वेबस चैट कर
              सकतेहैं, डेट कर सकतेहैं, शेयर कर सकतेहैं और एक दूसरेको स्वाभाविक
              रूप सेखोज सकतेहैं।
              <br />
              <br />
              इसके अलावा, <b>Hip Hop Daters</b> (यूजर्स) मज़ेकरतेहुए सामग्री
              बनाकर और पोस्ट करके पैसेकमा सकतेहैं।
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
