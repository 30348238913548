import React from "react";
import "./sec7.css";
import sec7_img from "../../../../Assets/new_img_s.png";

const Section7 = () => {
  return (
    <div className="container5">
      <div className="fifth-sec">
        <div className="left-side-font-AR">
          <h1>
            تسجيل سهل وبسيط <br />
            تطبيقات للاستخدام
          </h1>
          <p>
            {" "}
            الحل الوحيد للمواعدة،
            <br />
            الترفيه والموسيقى والمرح وأكثر من ذلك.
          </p>
          <br />
          <br />
          <p className="pre">
            <b>اكتشاف والدردشة والمشاركة والتاريخ.</b>
          </p>
        </div>
        <div className="right-side-img_nine">
          <img src={sec7_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
