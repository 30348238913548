import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">Was ist das Besondere an HipHop.Dating?</h1>
          <p class="div-of-data-heading">
            "Bei HipHop.Dating können HipHop-Dater (also Nutzer) wählen, ob sie
            sich nur für Dating, Geschäftsmöglichkeiten, beruflichen Aufstieg,
            neue Freundschaften oder eine Kombination aus zwei oder drei
            Optionen oder sogar allen interessieren."
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Spaß und Abenteuer, Gesellschaft, Selbsterkundung, sich geschätzt
              und geliebt fühlen, persönliches Wachstum und sich selbst
              kennenlernen, emotionale Unterstützung, Sicherheit und
              Unterstützung, soziale Erweiterung, gemeinsame Aktivitäten,
              physische und emotionale Intimität und mehr
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Möglichkeiten für Kollaborationen und Partnerschaften,
              Sichtbarkeit erhöhen. Verbinde dich mit Personen, die deine Werte
              und Kultur teilen, und arbeite mit denen zusammen, die deine
              Vision teilen. Solche Verbindungen können für Einzelpersonen oder
              Unternehmen, die neue Partnerschaften oder Expansionsmöglichkeiten
              suchen, von unschätzbarem Wert sein.
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              Deine Sichtbarkeit erhöhen: Regelmäßige Aktivitäten und
              Engagement, wie das Posten von Inhalten, können dich auf dem Radar
              unserer Partner halten. Dazu gehören Plattenlabels,
              Filmproduzenten, Modemarken, Musikproduzenten, Verlage,
              Marketingagenturen und Werbetreibende sowie Branchenkollegen, die
              nach potenziellen Kandidaten, Talenten oder Kollaborateuren
              suchen.
              <br />
              <br /> Persönliches Branding: HipHop.Dating ermöglicht es dir,
              deine persönliche Marke oder dich selbst auf globaler Ebene
              aufzubauen und zu fördern.
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Das Knüpfen von Freundschaften über verschiedene kulturelle,
              soziale oder Altersgruppen hinweg kann Vorurteile und Stereotypen
              reduzieren und ein inklusiveres Denken und eine bessere Welt für
              alle fördern
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
