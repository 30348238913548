import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>About</h1>
      <p className="sec2_p_web">
        {" "}
        <b>HipHop.Dating</b> هو موقع عالمي للمواعدة والترفيه الاجتماعي منصة
        الشبكات.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> هو برنامج مواعدة عالمي و<br /> ترفيه اجتماعي منصة
        الشبكات.
      </p>
      <p className="pre-1">
        {" "}
        انشر فيديوهات قصيرة عن شخصيتك الحقيقية! كن حقيقيا، دعونا الخاص بك تاريخ
        قبولك كما أنت،
        <br />
        جذب المزيد من المتابعين، وكسب المزيد من المال.
      </p>
      <p className="pre-1-mobile-hi">
        {" "}
        انشر فيديوهات قصيرة عن شخصيتك الحقيقية! كن أصيلاً، دع رفيقك يتقبلك كما
        أنت، جذب المزيد من المتابعين، وكسب المزيد من المال.
      </p>

      <p className="pre-1">
        اعرض مواهبك الفريدة، من الرقص والأزياء إلى الغناء، موسيقى الراب، أو
        الكوميديا، والاستيلاء <br />
        انتباه العالم. استخدم هذه المنصة للتألق وفتح الأبواب أمام الجديد فرص.
      </p>
      <p className="pre-1-mobile-hi">
        اعرض نفسك ومهاراتك في الرقص وأزياءك مهاراتك في الغناء و/أو مهارات موسيقى
        الراب المهارات الكوميدية أو أي مهارات تمتلكها واكتشف!
      </p>
      <p className="pre-1">
        نعم، ادخل إلى عالم <b>HipHop.Dating</b> المثير الوجهة النهائية للأفراد و
        <br />
        الأشخاص الذين يبحثون عن المتعة والتواصل والقليل من عالم الترفيه! انغمس
        في تجربة اجتماعية مبهجة <br />
        <b>
          <i>حيث لا تكون مجرد ملف تعريف آخر، بل أنت نجم ينتظر التألق</i>
        </b>
        . أطلق العنان لنفسك الحقيقية مع <br />
        ميزة الفيديو لدينا - طريقة فريدة لتقديم شخصيتك الحقيقية. كن جريئا، كن
        أصيل،
        <br />
        ودع المطابقات المحتملة تقع على عاتق الشخص الذي أنت عليه حقًا.
      </p>
      <p className="pre-1-mobile-hi">
        نعم، ادخل إلى عالم <b>HipHop.Dating</b> المثير الوجهة النهائية للأفراد و
        الأشخاص الذين يبحثون عن المتعة والتواصل والقليل من عالم الترفيه! انغمس
        في تجربة اجتماعية مبهجة
        <b>
          <i>حيث لا تكون مجرد ملف تعريف آخر، بل أنت نجم ينتظر التألق</i>
        </b>
        . أطلق العنان لنفسك الحقيقية مع ميزة الفيديو لدينا - طريقة فريدة لتقديم
        شخصيتك الحقيقية. كن جريئا، كن أصيل، ودع المطابقات المحتملة تقع على عاتق
        الشخص الذي أنت عليه حقًا.
      </p>
      <p className="pre-1">
        ولكن هناك المزيد! قم بتحويل رحلة المواعدة الخاصة بك إلى احتفال مواهبك.
        سواء كنت <br />
        راقصة رائعة، أو أيقونة أزياء، أو مغنية أو مغني راب، أو لديك موهبة صنع
        الناس <br />
        اضحك، <b>HipHop.Dating</b> هو مسرحك. تتباهى بمهاراتك الخاصة، يجذب
        جمهوراً من المعجبين،
        <br />
        وشاهد ارتفاع عدد متابعيك. وتخيل ماذا؟ الذوق الخاص بك لا فقط اكسب القلوب؛
        ويمكنه أيضًا <br />
        تتحول إلى وسيلة مربحة. قم بإجراء اتصالات واكسب متابعين و من المحتمل أن
        يعزز <br /> الخاص بك الأرباح، كل ذلك أثناء الاستمتاع بإثارة المواعدة
        والشبكات الاجتماعية. <br />
      </p>
      <p className="pre-1">
        استعد لتكون جزءًا من مجتمع نابض بالحياة حيث لا تنتهي الإثارة أبدًا ويمكن
        لكل انتقاد <br />
        يؤدي إلى قصة جديدة مذهلة. انضم إلى <b>HipHop.Dating</b> اليوم وابدأ رحلة
        مليئة بالمرح والذوق،
        <br />
        وإمكانيات رائعة!
      </p>

      <p className="pre-1-mobile-hi">
        ولكن هناك المزيد! قم بتحويل رحلة المواعدة الخاصة بك إلى احتفال مواهبك.
        سواء كنت راقصة رائعة، أو أيقونة أزياء، أو مغنية أو مغني راب، أو لديك
        موهبة صنع الناس اضحك، <b>HipHop.Dating</b> هو مسرحك. تتباهى بمهاراتك
        الخاصة، يجذب جمهوراً من المعجبين، وشاهد ارتفاع عدد متابعيك. وتخيل ماذا؟
        الذوق الخاص بك لا فقط اكسب القلوب؛ ويمكنه أيضًا تتحول إلى وسيلة مربحة.
        قم بإجراء اتصالات واكسب متابعين و من المحتمل أن يعزز الخاص بك الأرباح،
        كل ذلك أثناء الاستمتاع بإثارة المواعدة والشبكات الاجتماعية.
      </p>
      <p className="pre-1-mobile-hi">
        استعد لتكون جزءًا من مجتمع نابض بالحياة حيث لا تنتهي الإثارة أبدًا ويمكن
        لكل انتقاد يؤدي إلى قصة جديدة مذهلة. انضم إلى <b>HipHop.Dating</b> اليوم
        وابدأ رحلة مليئة بالمرح والذوق، وإمكانيات رائعة!
      </p>
    </div>
  );
};

export default Section2;
