import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">HipHop.Dating的优点是什么？</h1>
          <p class="div-of-data-heading">
            "在HipHop.Dating，嘻哈约会者（也就是⽤户）可以选择他们感兴趣
            的事情，⽆论是仅仅约会、商业机会、职业发展、新的友谊，或者是
            两三种选项的结合，甚⾄是所有这些。”
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              乐趣与冒险、伴侣关系、⾃我发现、感受到被重视和爱护、个
              ⼈成⻓和了解⾃⼰、情感⽀持、安全与⽀持、社交扩展、共同活动、
              身体和情感亲密等等。
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              合作与伙伴关系的机会、提⾼能⻅度。与那些与你的价值
              观和⽂化共鸣的⼈建⽴联系，并与那些分享你愿景的⼈合作。这样的
              联系对于寻求新伙伴关系或扩张机会的个⼈或企业来说是⽆价的。
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              提⾼你的能⻅度：定期活动和参与，⽐如发布内容，可以让你始终出
              现在我们合作伙伴的雷达上。这些合作伙伴包括唱⽚公司、电影制⽚
              ⼈、时尚品牌、⾳乐制作⼈、出版商、营销代理商和⼴告商，以及寻
              找潜在候选⼈、⼈才或合作者的⾏业同⾏。
              <br />
              <br /> 个⼈品牌：HipHop.Dating让你可以在全球范围内建⽴和推⼴你的个
              ⼈品牌或⾃⼰。
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              在不同的⽂化、社会或年龄群体中建⽴友谊，可以减少偏⻅
              和成⻅，促进更加包容的⼼态和更美好的世界。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
