import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>О нас</h1>
      <p className="sec2_p_web">
        <b> HipHop.Dating</b> – это мировая платформа для знакомств и социальных
        развлечений.
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> – это мировая платформа для <br />
        знакомств и социальных развлечений.
      </p>
      <p className="pre-1">
        {" "}
        Публикуйте короткие видеоролики о себе настоящем! Будьте подлинными,
        позвольте вашим свиданиям принять вас
        <br />
        такими, какие вы есть, привлекайте больше подписчиков и зарабатывайте
        больше денег.
      </p>
      <p className="pre-1-mobile-hi">
        {" "}
        Публикуйте короткие видеоролики о себе настоящем! Будьте подлинными,
        позвольте вашим свиданиям принять вас такими, какие вы есть, привлекайте
        больше подписчиков и зарабатывайте больше денег.
      </p>

      <p className="pre-1">
        Продемонстрируйте свои уникальные таланты, будь то танец, мода, пение,
        рэп или комедия, и привлекайте внимание <br /> всего мира. Используйте
        эту платформу, чтобы засиять и открыть себе двери в новые возможности.
      </p>
      <p className="pre-1-mobile-hi">
        Продемонстрируйте свои уникальные таланты, будь то танец, мода, пение,
        рэп или комедия, и привлекайте внимание всего мира. Используйте эту
        платформу, чтобы засиять и открыть себе двери в новые возможности.
      </p>
      <p className="pre-1">
        Да, шагните в электризующий мир <b>HipHop.Dating</b>, воплощение мечты
        для одиноких и ищущих веселья,
        <br />
        связи и немного шоу- бизнеса! Погрузитесь в захватывающий социальный
        опыт,
        <br />
        <b>
          <i>где вы не просто еще один профиль, а звезда, готовая засиять.</i>
        </b>
        Раскройте свою истинную ю сущность <br />
        помощью нашей функции видео — уникального способа представить настоящего
        вас. Будьте смелыми,
        <br />
        искренними и позвольте потенциальным партнерам влюбиться в вас таких,
        какие вы есть.
      </p>
      <p className="pre-1-mobile-hi">
        Да, шагните в электризующий мир <b>HipHop.Dating</b>, воплощение мечты
        для одиноких и ищущих веселья, связи и немного шоу- бизнеса! Погрузитесь
        в захватывающий социальный опыт,
        <b>
          <i>где вы не просто еще один профиль, а звезда, готовая засиять.</i>
        </b>
        Раскройте свою истинную ю сущность помощью нашей функции видео —
        уникального способа представить настоящего вас. Будьте смелыми,
        искренними и позвольте потенциальным партнерам влюбиться в вас таких,
        какие вы есть.
      </p>
      <p className="pre-1">
        Но это еще не все! Превратите свое путешествие знакомств в праздник
        своих талантов. Будь вы ослепительным
        <br /> танцором, иконой моды, мелодичным певцом или рэпером, или имеете
        талант заставлять людей смеяться,
        <br /> <b>HipHop.Dating</b> – это ваша сцена. Показывайте свои особенные
        умения, завораживайте аудиторию
        <br /> поклонников и наблюдайте, как растет ваша популярность. И знаете
        что? Ваш талант не только завоевывает
        <br />
        сердца; он также может превратиться в прибыльный канал. Заводите
        знакомства, увеличивайте число
        <br /> подписчиков и, возможно, повышайте свои доходы, наслаждаясь
        увлекательным миром свиданий и<br /> социального взаимодействия.
      </p>
      <p className="pre-1">
        Готовьтесь стать частью яркого сообщества, где волнение никогда не
        заканчивается, и каждое движение может <br /> привести к удивительным
        новым открытиям. Присоединяйтесь к <b>HipHop.Dating</b> сегодня и<br />{" "}
        начните путешествие, полное радости, стиля и фантастических
        возможностей!
      </p>

      <p className="pre-1-mobile-hi">
        Но это еще не все! Превратите свое путешествие знакомств в праздник
        своих талантов. Будь вы ослепительным танцором, иконой моды, мелодичным
        певцом или рэпером, или имеете талант заставлять людей смеяться,
        HipHop.Dating – это ваша сцена. Показывайте свои особенные умения,
        завораживайте аудиторию поклонников и наблюдайте, как растет ваша
        популярность. И знаете что? Ваш талант не только завоевывает сердца; он
        также может превратиться в прибыльный канал. Заводите знакомства,
        увеличивайте число подписчиков и, возможно, повышайте свои доходы,
        наслаждаясь увлекательным миром свиданий и социального взаимодействия.
      </p>
      <p className="pre-1-mobile-hi">
        Готовьтесь стать частью яркого сообщества, где волнение никогда не
        заканчивается, и каждое движение может привести к удивительным новым
        открытиям. Присоединяйтесь к HipHop.Dating сегодня и начните
        путешествие, полное радости, стиля и фантастических возможностей!
      </p>
    </div>
  );
};

export default Section2;
