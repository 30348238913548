import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>Warum und wie sind wir anders?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              Der Vorteil von HipHop.Dating im Vergleich
              <br />
              Bei vielen anderen Dating-Plattformen ist das alles
              <br />
              Benutzer haben bereits eine gemeinsame große
              <br />
              Leidenschaft für Leben, das die „Liebe zum Hip Hop <br /> und
              seiner Kultur“ ist.
              <br />
              <br />
              Andere Plattformen, die es Benutzern ermöglichen, <br />
              das-Potenzial vorab zu prüfen Übereinstimmungen <br />
              die sie sehen, werden herausgefiltert unerwünschte <br />
              Rassen,Altersgruppen oder Orte. Sie filtern, <br />
              Sie Geben Sie „interessiert/nicht“ an, wiederholen
              <br />
              Sie usw. Einige Apps sind es Mithilfe der Algorithmen
              <br /> wählen Sie aus, wen Sie wann sehen möchten.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              Das Fehlen des grundlegenden gemeinsamen Interesses,
              <br />
              der Leidenschaft und der Liebe unter den Benutzern <br />
              macht es schwierig Viele Dating-Apps helfen Nutzern <br />
              realistisch bei der Suche ihre Seelenverwandten.
              <br />
              <br />
              Bei <b>HipHop.Dating</b> wissen Benutzer bereits, dass sie
              <br />
              teilen das gleiche Interesse, die gleiche Leidenschaft <br />
              und die gleiche Liebe für Hip Hop und seine Kultur
              <br />
              (Lifestyle, Musik, Mode, Tanz, Poesie, Kunst usw.).Sie
              <br />
              können einfach chatten, Sich verabreden, austauschen <br /> und
              auf natürliche Weise entdecken.
              <br />
              <br />
              Darüber hinaus können <b>Hip-Hop-Daten</b> (Benutzer) <br />{" "}
              machen Geld verdienen, indem Sie Inhalte erstellen und <br />
              veröffentlichen und dabei Spaß haben.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
