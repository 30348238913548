import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>关于</h1>
      <p className="sec2_p_web">
        {" "}
        <b> HipHop.Dating</b> 是⼀个全球约会和社交娱乐⽹络平台。
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> 是⼀个全球约会和社交娱乐⽹络平台。
      </p>
      <p className="pre-1">
        {" "}
        发布真实的⾃⼰的短视频！保持真实，让你的约会对象接受你的本来 ⾯⽬，
        <br />
        吸引更多的关注者，并赚更多的钱。
      </p>
      <p className="pre-1-mobile">
        {" "}
        发布真实的⾃⼰的短视频！保持真实，
        <br />
        让你的约会对象接受你的本来 ⾯⽬，
        <br />
        吸引更多的关注者，并赚更多的钱。
      </p>

      <p className="pre-1">
        展示你独特的才能，从舞蹈和时尚到唱歌、说唱或喜剧，吸引全球的
        <br />
        注意。利⽤这个平台来闪耀并打开通往新机会的⼤⻔。
      </p>
      <p className="pre-1-mobile">
        展示你独特的才能，从舞蹈和时尚到唱歌
        <br />
        说唱或喜剧，吸引全球的 注意
        <br />
        利⽤这个平台来闪耀并打开通往新机会的⼤⻔。
      </p>
      <p className="pre-1">
        是的，请踏⼊充满激情的 <b>HipHop.Dating</b>, 世界，这是单身⼈⼠和寻求
        <br />
        乐趣、连接以及⼀点点娱乐的终极⽬的地！投身到⼀个令⼈振奋的社
        <br />
        <b>
          <i>在這裡，您不僅僅是另一個人物，而是一顆等待發光的明星。</i>
        </b>
        釋放真實的自我
        <br />
        星。通过我们的视频功能释放真正的⾃⼰——这是⼀种介绍真实你的
        <br />
        独特⽅式。要⼤胆，要真实，让潜在的匹配对象爱上真正的你。
      </p>
      <p className="pre-1">
        但还有更多！将你的约会之旅转变成你才能的庆祝。⽆论你是⼀个出 <br />
        ⾊的舞者、时尚偶像、悦⽿的歌⼿或说唱歌⼿，还是善于逗⼈发笑， <br />
        <b>HipHop.Dating</b> 都是你的舞台。炫耀你的特殊才能，吸引⼀群崇拜
        <br />
        者，看着你的关注者数量飙升。你知道吗？你的才华不仅仅赢得了
        <br />
        ⼼，还可以成为⼀个有利可图的途径。建⽴联系，赚取关注者，可能 <br />
        提⾼你的收⼊，所有这些都是在享受约会和社交⽹络的乐趣的同时完 成的。{" "}
        <br />
      </p>
      <p className="pre-1">
        准备好加⼊⼀个充满活⼒的社区，在这⾥兴奋永不⽌息，每⼀次滑动 <br />
        都可能带来⼀个令⼈惊⼈的新故事。今天加⼊ <b>HipHop.Dating</b> 开始
        <br />
        ⼀段充满乐趣、⻛采和令⼈兴奋的可能性的旅程！
      </p>
      <p className="pre-1-mobile">
        是的，请踏⼊充满激情的 <b>HipHop.Dating</b>,<br />
        世界，这是单身⼈⼠和寻求 乐趣、 <br />
        连接以及⼀点点娱乐的终极⽬的地！
        <br />
        投身到⼀个令⼈振奋的社
        <br />
        <b>
          <i>
            在這裡，您不僅僅是另一個人物， <br />
            而是一顆等待發光的明星。
          </i>
        </b>
        <br />
        釋放真實的自我
        <br />
        星。通过我们的视频功能释放真正的⾃⼰ <br />
        ——这是⼀br 种介绍真实你的
        <br />
        种介绍真实你的 独特⽅式。要⼤胆，要真实， <br />
        让潜在的匹配对象爱上真正的你。
      </p>
      <p className="pre-1-mobile">
        但还有更多！将你的约会之旅转变成你才能的庆祝。
        <br />
        ⽆论你是⼀个出 ⾊的舞者、时尚偶像、悦⽿的歌⼿或说唱歌⼿， <br />
        还是善于逗⼈发笑，
        <b>HipHop.Dating</b> 都是你的舞台。 <br />
        炫耀你的特殊才能，吸引⼀群崇拜 者，看着你的关注者数量飙升。 <br />
        你知道吗？你的才华不仅仅赢得了
        <br />
        ⼼，还可以成为⼀个有利可图的途径。建⽴联系， <br />
        赚取关注者，可能 提⾼你的收⼊， <br />
        所有这些都是在享受约会和社交⽹络的乐趣的同时完 成的。 <br />
      </p>
      <p className="pre-1-mobile">
        准备好加⼊⼀个充满活⼒的社区，在这⾥兴奋永不⽌息，
        <br />
        每⼀次滑动 都可能带来⼀个令⼈惊⼈的新故事。
        <br /> 今天加⼊
        <b>HipHop.Dating</b> 开始 ⼀段充满乐趣、
        <br />
        ⻛采和令⼈兴奋的可能性的旅程！
      </p>
    </div>
  );
};

export default Section2;
