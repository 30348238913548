import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        हमसेजुड़ें HipHop.Dating मेंऔर डुबकी लगाए <br />
        दुनिया की वैश्विक संस्कृति के जीवंत दिल में।
      </h1>
      <h1 className="nine_heding_mobile">
        हमसेजुड़ें HipHop.Dating मेंऔर डुबकी लगाएँ दुनिया की वैश्विक संस्कृति के
        जीवंत दिल में।
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            हम एक समुदाय हैंजो एकता को गलेलगाता हैऔर मानवता का
            <br />
            पूरी तरह सेजश्न मनाता है। यहाँ, आपको डेटिंग, संगीत, मज़ा,
            <br />
            फै शन, और मनोरंजन का गतिशील संगम मिलेगा। लेकिन HipHop.Dating <br />
            इससे कहीं अधिक है। that. हिप हॉप, जो 11 अगस्त, 1973 <br />
            को न्यूयॉर्क के ब्रोंक्स मेंउत्पन्न हुआ, बन गया ह पहला सच्चा वैश्विक
            संस्कृति,
            <br />
            दुनिया भर मेंलोगों को एक शांतिपूर और सभ्य तरीके सेएकजुट करता है।
            <br /> <br />
            यह संस्कृति बलपूर्वक नहीं उभरी है, जैसेकु छ ऐतिहासिक संस्कृतिया
            <br />
            धार्मिक, जातीय, या सैन्य प्रभावों द्वारा संचालित होती हैं। इसके
            बजाय, हिप हॉप ह<br />
            स्वाभाविक रूप सेविकसित हुआ, राजनीतिक, संगीतमय <br />
            और सामाजिक स्थापनाओ ंसेप्रारंभिक प्रतिरोध को पार कर गया। इसकी <br />
            प्रामाणिकता और मजबूत आधार न इसेविजयी बनाया और वैश्विक रूप
            <br />
            सेप्रमुख सांस्कृतिक शक्ति बनाया।
            <br /> <br />
            आज, हिप हॉप सिर्फ एक संगीत शैली सेअधिक है; यह एक सांस्कृतिक
            <br />
            शक्ति हैजो फै शन, राजनीति, और अधिक को प्रभावित करती है। यह बनाता ह
            <br />
            दुनिया भर मेंयुवा और बूढ़ों के लिए मुख्य सांस्कृतिक पहचान।
            <br /> <br />
            चाहेआपकी संगीत पसंद कु छ भी हो, HipHop.Dating आपका स्वागत <br />
            करता ह एकता, प्यार, शांति, और सामंजस्य के उत्सव में। यहाँ, आप
            <br />
            डेटिंग, संगीत, फै शन, मनोरंजन और रचनात्मकता की दुनिया मेंगोता
            <br />
            लगा सकतेहैं। यह के वल अपनी व्यक्तिगतता व्यक्त करनेका मौका नहीं ह
            <br />
            बल्कि अपनी अनूठी अभिव्यक्तियों के माध्यम सेपैसा कमानेका भी अवसर है।
            <br />
            हमारे साथ एकजुट ह हिप हॉप संस्कृति की गतिशील और विविध दुनिया में।
          </p>
          <p className="nine_pre_text_mobile">
            हम एक समुदाय हैंजो एकता को गलेलगाता हैऔर मानवता का पूरी तरह सेजश्न
            मनाता है। यहाँ, आपको डेटिंग, संगीत, मज़ा, फै शन, और मनोरंजन का
            गतिशील संगम मिलेगा। लेकिन HipHop.Dating इससे कहीं अधिक है। that. हिप
            हॉप, जो 11 अगस्त, 1973 को न्यूयॉर्क के ब्रोंक्स मेंउत्पन्न हुआ, बन
            गया ह पहला सच्चा वैश्विक संस्कृति, दुनिया भर मेंलोगों को एक शांतिपूर
            और सभ्य तरीके सेएकजुट करता है।
            <br /> <br />
            यह संस्कृति बलपूर्वक नहीं उभरी है, जैसेकु छ ऐतिहासिक संस्कृतिया
            धार्मिक, जातीय, या सैन्य प्रभावों द्वारा संचालित होती हैं। इसके
            बजाय, हिप हॉप ह स्वाभाविक रूप सेविकसित हुआ, राजनीतिक, संगीतमय और
            सामाजिक स्थापनाओ ंसेप्रारंभिक प्रतिरोध को पार कर गया। इसकी
            प्रामाणिकता और मजबूत आधार न इसेविजयी बनाया और वैश्विक रूप सेप्रमुख
            सांस्कृतिक शक्ति बनाया।
            <br /> <br />
            आज, हिप हॉप सिर्फ एक संगीत शैली सेअधिक है; यह एक सांस्कृतिक शक्ति
            हैजो फै शन, राजनीति, और अधिक को प्रभावित करती है। यह बनाता ह दुनिया
            भर मेंयुवा और बूढ़ों के लिए मुख्य सांस्कृतिक पहचान।
            <br /> <br />
            चाहेआपकी संगीत पसंद कु छ भी हो, HipHop.Dating आपका स्वागत करता ह
            एकता, प्यार, शांति, और सामंजस्य के उत्सव में। यहाँ, आप डेटिंग,
            संगीत, फै शन, मनोरंजन और रचनात्मकता की दुनिया मेंगोता लगा सकतेहैं।
            यह के वल अपनी व्यक्तिगतता व्यक्त करनेका मौका नहीं ह बल्कि अपनी अनूठी
            अभिव्यक्तियों के माध्यम सेपैसा कमानेका भी अवसर है। हमारे साथ एकजुट ह
            हिप हॉप संस्कृति की गतिशील और विविध दुनिया में।
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
