import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">Ni nini Kinachovutia Kuhusu HipHop.Dating?</h1>
          <p class="div-of-data-heading">
            " Katika HipHop.Dating, watumiaji wa hiphop wanaweza kuchagua kama
            wanapendezwa na kutongoza pekee, fursa za biashara, kuendeleza kazi,
            urafiki mpya, au mchanganyiko wa chaguo mbili au tatu, au hata vyote
            kwa pamoja."
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Furaha na Uadventisti, Kuwa na Mwenzake, Kujitambua, Kujihisi
              Kuthaminiwa na Kupendwa, Ukuaji Binafsi na Kujijua Zaidi, Msaada
              wa Kihisia, Usalama na Msaada, Kuongezeka kwa Mtandao wa Kijamii,
              Kufanya Shughuli Pamoja, Ukuruba wa Kimwili na Kihisia, na
              mengineyo.
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Fursa za Ushirikiano na Ubia, Kuongeza Umaarufu. Unganisha na watu
              ambao wana thamani na utamaduni unaofanana na wako, na ushirikiane
              na wale wanaoshiriki maono yako. Aina hizi za mawasiliano zinaweza
              kuwa na thamani kubwa kwa watu binafsi au biashara zinazotafuta
              ushirikiano mpya au fursa za upanuzi.
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              Kuongeza Umaarufu Wako: Ushiriki wa mara kwa mara na ujumuishaji,
              kama vile kuweka maudhui, kunaweza kukufanya uonekane zaidi kwa
              washirika wetu. Hawa ni pamoja na lebo za rekodi, watayarishaji wa
              filamu, chapa za mitindo, watayarishaji wa muziki, wachapishaji,
              wakala wa masoko, na watangazaji, pamoja na wataalamu wa tasnia
              wanaotafuta wagombea watarajiwa, vipaji, au washirikiano.
              <br />
              <br /> Kujenga Chapa Binafsi: HipHop.Dating inakupa uwezo wa
              kujenga na kutangaza chapa yako binafsi au nafsi yako kwenye
              kiwango cha kimataifa
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Marafiki Wapya: Kujenga urafiki kati ya makundi tofauti ya
              kitamaduni, kijamii, au kiumri kunaweza kupunguza upendeleo na
              dhana potofu, kukuza mtazamo wa kujumuisha zaidi na dunia bora kwa
              wote.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
