import Navbar from "../../components/UI/Navbar/index";
import Section1 from "./scection/section1";
import Section2 from "./scection/section2";
import Section3 from "./scection/section3";
import Section4 from "./scection/section4";
import Section5 from "./scection/section5";
import Footer from "../../components/UI/Footer/index";
import "./landing.css";
import Section6 from "./scection/section6";
import Section7 from "./scection/section7";
import Section8 from "./scection/section8";
import Section9 from "./scection/section9";
import Section10 from "./scection/section10";

const LandingageRS = () => {
  return (
    <div>
      <Navbar />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
      <Section9 />
      <Section10 />
      <Footer />
    </div>
  );
};

export default LandingageRS;
