import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>なぜ、そしてどのように違うのでしょうか?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              比較した場合の HipHop.Dating の利点
              <br />
              他の多くの出会い系プラットフォームでは、
              <br />
              それだけがすべてです ユーザーはすでに共通の大 <br />
              きな情熱を持 っています それは「ヒップホップと
              <br />
              そのカ ルチャ ーへの愛」です。
              <br />
              <br />
              ユーザーが 潜在的な可能性を事前に選別できる他の <br />
              プラ ットフォーム 表示された一致をフィルタリングし
              <br />
              brて除外 する 望ましくない人種、年齢、または場所。
              <br />
              フィルター をかけるのは あなたです。「興味がある
              <br />
              /ない」 を繰り返して示します。 一部のアプリは
              <br />
              アルゴリズムを使用して 誰にいつ案内するか
              <br />
              を選択します。
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              基本的な共通の関心、情熱 、愛情の欠如
              <br />
              ユーザー間での使用が困難になる
              <br /> ユーザーが実際に見つけるのに役立つ多
              <br />
              くの出会い系アプリが あります
              <br />
              彼らのソウルメイト。
              <br />
              <br />
              <b>HipHop.Dating</b> では、ユーザーはすでに自分のこ
              <br />
              とを知っ ています。 ～に対して同じ興味、情熱、
              <br />
              愛を共有する ヒップホップとそのカルチャー
              <br />
              （ライフスタイル、音楽、ファッション、 ダンス、
              <br />
              詩、芸術など）。 彼らは単にチャットするこ
              <br />
              ともできますが、 自然にデートしたり、共有したり、
              <br />
              お互いを発見したりできます。
              <br />
              <br />
              さらに、<b>ヒップホップ デート</b> (ユーザー) は、
              <br />
              楽しみながらコンテンツを作成・投稿すること
              <br />
              でお金を稼 ぐことができます。
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
