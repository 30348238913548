import React from "react";
import "./sec9.css";
import Sec9_img from "../../../../Assets/download1.png";
import appstore from "../../../../Assets/iOS_btn.png";
import playstore from "../../../../Assets/play_store_btn.png";
import { Link } from "react-scroll";

const section9 = () => {
  return (
    <div className="container7" id="Sec9">
      <div className="saven-sec">
        <div className="conten-1">
          <div className="heading_text_sec9">
            <h1>Vorabregistrierung</h1>
            <button className="download_btn">
              <Link to="Contact" offset={-100} duration={500} smooth={true}>
                Kostenlose Registrierung hier
              </Link>
            </button>
          </div>
          <h3 className="h3teg">
            Hip Hop Dating<p className="ptag">®</p> App herunter
          </h3>
          <p>
            <b>
              {" "}
              Entdecken, Chatten, Teilen, Daten & <br />
              Geld verdienen
            </b>
          </p>

          <h5>App bald verfügbar!</h5>
          <div className="download-social">
            <div className="icon-img">
              <img src={appstore} alt="" />
              <img src={playstore} alt="" />
            </div>
          </div>
        </div>

        <div className="conten-img">
          <img src={Sec9_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default section9;
