import React from "react";
import "./Sec6.css";
import Sec6_img from "../../../../Assets/patented_img.png";

const Section6 = () => {
  return (
    <div class="container4">
      <div class="four-sec">
        <div class="left-side-img">
          <img src={Sec6_img} alt="" />
        </div>
        <div class="right-side">
          <div class="main-heading">
            <div class="sec4h1">
              <div class="sec4-h1">
                <h1>Patented innovative</h1>
                <h1>concept and technologies</h1>
              </div>
            </div>
          </div>

          <p>
            More fun, more entertainment and make more money.
            <br />
            Its more than dating!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section6;
