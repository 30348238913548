import React from "react";
import "./sec1.css";
import applestore from "../../../../Assets/iOS_btn.png";
import playstore from "../../../../Assets/play_store_btn.png";
import sec1img from "../../../../Assets/header_img.png";
const Section1 = () => {
  return (
    <div className="head-sec" id="Sec1">
      <div className="cont-1">
        <div className="text-area">
          <h1>
            不仅仅是约会<span>&trade;</span>
          </h1>
          <p>
            这是对爱情、⽣活、
            <br />
            ⾳乐、 乐趣和时尚的庆祝，
            <br />
            同时还可以赚钱。
          </p>
          <div className="img2">
            <p>即将推出！</p>
            <div className="box-1">
              <img src={applestore} alt="" />
              <img src={playstore} alt="" />
            </div>
          </div>
        </div>
        <div className="header-img">
          <img src={sec1img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section1;
