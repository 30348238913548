import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        Únete a nosotros en HipHop.Dating e inmérgete en el <br /> corazón
        vibrante de la cultura global del mundo.
      </h1>
      <h1 className="nine_heding_mobile">
        Únete a nosotros en HipHop.Dating e inmérgete en el corazón vibrante de
        la cultura global del mundo.
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            Somos una comunidad que abraza la unidad y celebra la humanidad en{" "}
            <br />
            su totalidad. Aquí, encontrarás una fusión dinámica de citas,
            música,
            <br />
            diversión, moda y entretenimiento. Pero HipHop.Dating es mucho más{" "}
            <br />
            que eso. El Hip Hop, nacido el 11 de agosto de 1973 en el Bronx,{" "}
            <br />
            Nueva York, se ha convertido en la primera cultura global verdadera,
            <br />
            uniendo a personas de todo el mundo de manera pacífica y civilizada.
            <br />
            <br />
            Esta cultura no ha surgido a través de la fuerza, como algunas{" "}
            <br />
            culturas históricas impulsadas por influencias religiosas, étnicas o{" "}
            <br />
            militares. En cambio, el Hip Hop ha evolucionado naturalmente,{" "}
            <br />
            superando la resistencia inicial de los establecimientos políticos,{" "}
            <br />
            musicales y sociales. Su autenticidad y base sólida le han permitido{" "}
            <br />
            triunfar y convertirse en la fuerza cultural líder a nivel mundial.{" "}
            <br /> <br />
            Hoy, el Hip Hop trasciende ser solo un género musical; es una <br />
            potencia cultural que influye en la moda, la política y más. Forma{" "}
            <br />
            la identidad cultural central para jóvenes y mayores en todo el{" "}
            mundo.
            <br /> <br />
            No importa tu gusto musical, HipHop.Dating te da la bienvenida a una{" "}
            <br />
            celebración de unidad, amor, paz y armonía. Aquí, puedes sumergirte{" "}
            <br />
            en el mundo de las citas, la música, la moda, el entretenimiento y{" "}
            <br />
            la creatividad. Es una oportunidad no solo para expresar tu
            individualidad, <br /> sino también para ganar dinero a través de
            tus expresiones únicas. <br /> Únete a nosotros en el dinámico y
            diverso mundo de la cultura Hip Hop.
          </p>
          <p className="nine_pre_text_mobile">
            Somos una comunidad que abraza la unidad y celebra la humanidad en
            su totalidad. Aquí, encontrarás una fusión dinámica de citas,
            música, diversión, moda y entretenimiento. Pero HipHop.Dating es
            mucho más que eso. El Hip Hop, nacido el 11 de agosto de 1973 en el
            Bronx, Nueva York, se ha convertido en la primera cultura global
            verdadera, uniendo a personas de todo el mundo de manera pacífica y
            civilizada.
            <br /> <br />
            Esta cultura no ha surgido a través de la fuerza, como algunas
            culturas históricas impulsadas por influencias religiosas, étnicas o
            militares. En cambio, el Hip Hop ha evolucionado naturalmente,
            superando la resistencia inicial de los establecimientos políticos,
            musicales y sociales. Su autenticidad y base sólida le han permitido
            triunfar y convertirse en la fuerza cultural líder a nivel mundial.
            <br /> <br />
            Hoy, el Hip Hop trasciende ser solo un género musical; es una
            potencia cultural que influye en la moda, la política y más. Forma
            la identidad cultural central para jóvenes y mayores en todo el
            mundo.
            <br /> <br />
            No importa tu gusto musical, HipHop.Dating te da la bienvenida a una
            celebración de unidad, amor, paz y armonía. Aquí, puedes sumergirte
            en el mundo de las citas, la música, la moda, el entretenimiento y
            la creatividad. Es una oportunidad no solo para expresar tu
            individualidad, sino también para ganar dinero a través de tus
            expresiones únicas. Únete a nosotros en el dinámico y diverso mundo
            de la cultura Hip Hop.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
