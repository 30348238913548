import React from "react";
import "./Sec3.css";
import sec3_1 from "../../../../Assets/SEC3_1_datingcheck.png";
import sec3_2 from "../../../../Assets/SEC3_2_buisness.png";
import sec3_3 from "../../../../Assets/SEC3_3_carrer.png";
import sec3_4 from "../../../../Assets/SEC3_4_newfriends.png";

const Section3 = () => {
  return (
    <div class="container10">
      <div class="main-div-of-container10">
        <div class="div_of-heading">
          <h1 class="txt-head">Что такое великолепное в HipHop.Dating?</h1>
          <p class="div-of-data-heading">
            "На HipHop.Dating, поклонники хип-хопа (или пользователи) могут
            выбирать, интересуются ли они только знакомствами,
            бизнес-возможностями, карьерным ростом, новыми”
          </p>
        </div>
        <div class="div_of_body_10">
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_1} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Веселье и приключения, компаньонство, самопознание, ощущение
              ценности и любви, личностный рост и познание себя, эмоциональная
              поддержка, безопасность и поддержка, социальное расширение,
              совместные мероприятия, физическая и эмоциональная близость и
              многое другое.
            </p>
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_2} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Возможности для сотрудничества и партнерства, увеличение
              видимости. Завязывайте контакты с людьми, разделяющими ваши
              ценности и культуру, и сотрудничайте с теми, кто разделяет ваше
              видение. Такие связи могут быть бесценными для людей или бизнеса,
              ищущих новые партнерства или возможности для расширения.
            </p>
          </div>

          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_3} alt="" class="img_1_cntn10" />
            </div>
            {/* <div class="div_of_both"> */}
            <p class="right_side_div_2">
              Повышение вашей видимости: Регулярная активность и участие, такие
              как публикация контента, могут поддерживать ваше присутствие на
              радаре наших партнеров. К ним относятся звукозаписывающие
              компании, кинопродюсеры, модные бренды, музыкальные продюсеры,
              издатели, маркетинговые агентства и рекламодатели, а также коллеги
              по отрасли, ищущие потенциальных кандидатов, таланты или
              сотрудников.
              <br />
              <br /> Личный Брендинг: HipHop.Dating позволяет вам строить и
              продвигать ваш личный бренд или себя на глобальном уровне.
            </p>

            {/* <p class="right_side_div_2"></p> */}
            {/* </div> */}
          </div>
          <div class="main_div_of_body_cntn10">
            <div class="left_side_div">
              <img src={sec3_4} alt="" class="img_1_cntn10" />
            </div>
            <p class="right_side_div_2">
              Строительство дружбы между различными культурными, социальными или
              возрастными группами может уменьшить предубеждения и стереотипы,
              способствуя более инклюзивному мышлению и лучшему миру для всех.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section3;
