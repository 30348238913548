import React from "react";
import "./sec8.css";
import sec9_img from "../../../../Assets/sec-9.png";

const Section8 = () => {
  return (
    <div className="container9">
      <h1 className="nine_heding">
        Junte-se a nós no HipHop.Dating e mergulhe no <br />
        coração vibrante da cultura global do mundo.
      </h1>
      <h1 className="nine_heding_mobile">
        Junte-se a nós no HipHop.Dating e mergulhe no coração vibrante da
        cultura global do mundo.
      </h1>
      <div className="nine-sec">
        <div className="right-side-img">
          <img src={sec9_img} alt="" style={{ padding: "0" }} />
        </div>
        <div className="left-side-font_nine">
          <p className="nine_pre_text">
            Somos uma comunidade que abraça a unidade e celebra a
            <br />
            humanidade em sua totalidade. Aqui, você encontrará uma fusão
            <br />
            dinâmica de namoro, música, diversão, moda e entretenimento. that.{" "}
            <br />
            Mas o HipHop.Dating é muito mais do que isso.
            <br />
            O Hip Hop, nascido em 11 de agosto de 1973, no Bronx, Nova
            <br />
            York, tornou-se a primeira verdadeira cultura global, unindo
            <br />
            pessoas ao redor do mundo de maneira pacífica e civilizada.
            <br /> <br />
            Esta cultura surgiu não através da força, como algumas culturas{" "}
            <br />
            históricas impulsionadas por influências religiosas, étnicas ou{" "}
            <br />
            militares. Em vez disso, o Hip Hop evoluiu naturalmente, <br />
            superando a resistência inicial de estabelecimentos políticos,{" "}
            <br />
            musicais, e sociais. Sua autenticidade e base sólida permitiram que{" "}
            <br />
            triunfasse e se tornasse a principal força cultural globalmente.
            <br /> <br />
            Hoje, o Hip Hop transcende ser apenas um gênero musical; é uma{" "}
            <br />
            potência cultural influenciando moda, política e mais. Ele forma a{" "}
            <br />
            identidade cultural central para jovens e idosos em todo o mundo.{" "}
            <br /> <br />
            Não importa o seu gosto musical, o HipHop.Dating convida você para{" "}
            <br />
            uma celebração de unidade, amor, paz e harmonia. Aqui, você pode{" "}
            <br />
            mergulhar no mundo do namoro, música, moda, entretenimento e <br />
            criatividade. É uma chance não apenas de expressar sua <br />
            individualidade mas também de ganhar dinheiro através de suas <br />
            expressões únicas. Una-se a nós no mundo dinâmico e diversificado
            <br />
            da cultura Hip Hop.
          </p>
          <p className="nine_pre_text_mobile">
            Somos uma comunidade que abraça a unidade e celebra a humanidade em
            sua totalidade. Aqui, você encontrará uma fusão dinâmica de namoro,
            música, diversão, moda e entretenimento. Mas o HipHop.Dating é muito
            mais do que isso. O Hip Hop, nascido em 11 de agosto de 1973, no
            Bronx, Nova York, tornou-se a primeira verdadeira cultura global,
            unindo pessoas ao redor do mundo de maneira pacífica e civilizada.
            <br /> <br />
            Esta cultura surgiu não através da força, como algumas culturas
            históricas impulsionadas por influências religiosas, étnicas ou
            militares. Em vez disso, o Hip Hop evoluiu naturalmente, superando a
            resistência inicial de estabelecimentos políticos, musicais, e
            sociais. Sua autenticidade e base sólida permitiram que triunfasse e
            se tornasse a principal força cultural globalmente.
            <br /> <br />
            Hoje, o Hip Hop transcende ser apenas um gênero musical; é uma
            potência cultural influenciando moda, política e mais. Ele forma a
            identidade cultural central para jovens e idosos em todo o mundo.
            <br /> <br />
            Não importa o seu gosto musical, o HipHop.Dating convida você para
            uma celebração de unidade, amor, paz e harmonia. Aqui, você pode
            mergulhar no mundo do namoro, música, moda, entretenimento e
            criatividade. É uma chance não apenas de expressar sua
            individualidade mas também de ganhar dinheiro através de suas
            expressões únicas. Una-se a nós no mundo dinâmico e diversificado da
            cultura Hip Hop.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
