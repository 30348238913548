import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../comman/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Backdrop, CircularProgress } from "@mui/material";
const Privacy = () => {
  const navigate = useNavigate();
  const [ApiData, setApiData] = useState();
  const [ApiError, setApiError] = useState();
  const [loading, setloading] = useState(false);

  const getApiData = async () => {
    setloading(true);
    try {
      const Response = await axios.get("/get/privacy");
      // console.log("🚀 res....", res)
      if (Response.data.status == 1) {
        setApiData(Response.data.data.content);
        setloading(false);
        // toast.success(`${Response.data.message}`, { theme: "colored" });
      }
    } catch (error) {
      setApiError(error);
      setloading(false);
    }
  };
  useEffect(() => {
    getApiData();
  }, []);

  return (
    <>
      <div className="main_div_trems">
        <p onClick={() => navigate("/")}>&#8592;</p>
        <div className="inner_div_trems">
          <div className="heading_text">
            <h1>Privacy policy & Cookies</h1>
          </div>
          <div className="inner_text_main_div">
            <div dangerouslySetInnerHTML={{ __html: ApiData }}></div>
          </div>
        </div>
      </div>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Privacy;
