import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>¿Por qué y en qué somos diferentes?</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text">
            <p>
              La ventaja de HipHop.Dating en comparación <br />
              con muchas otras plataformas de citas es que <br />
              todo Los usuarios ya tienen una gran pasión común <br />
              en vida que es el “Amor por el Hip Hop y su cultura”.
              <br />
              <br />
              <br />
              Otras plataformas permiten a los usuarios <br />
              preseleccionar los posibles coincidencias que <br />
              ven, filtrando razas, edades o ubicaciones no <br />
              deseadas. Filtras, indicas 'interesado/no interesado',
              <br />
              repites, etc. Algunas aplicaciones están utilizando <br />
              algoritmos para elegir a quién mostrarte, y cuándo.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text">
            <p>
              La falta del interés común básico, la pasión. <br /> Y el amor
              entre las usuarias lo hace difícil para
              <br /> muchas aplicaciones de citas para ayudar de manera
              <br />
              realista a los usuarios a encontrar a sus almas gemelas.
              <br />
              <br />
              En <b>HipHop.Dating</b>, los usuarios ya saben que
              <br />
              compartir el mismo interés, pasión y amor por
              <br />
              Hip Hop y su cultura (estilo de vida, música, moda,
              <br />
              danza, poesía, artes, etc). Pueden simplemente
              <br />
              charlar, Salgan, compartan y descubran al otro de forma
              <br />
              <br />
              natural. Además, los <b>Hip Hop Daters</b> (usuarios)
              <br />
              pueden hacer dinero creando y publicando contenidos mientras te
              diviertes
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
