import React from "react";
import "./sec2.css";

const Section2 = () => {
  return (
    <div className="container2" id="Sec2">
      <h1>हमारे बारे में</h1>
      <p className="sec2_p_web">
        {" "}
        <b> HipHop.Dating</b> एक वैश्विक डेटिंग और सामाजिक मनोरंजन नेटवर्किंग
        प्लेटफॉर्म है
      </p>
      <p className="sec2_p_mobile">
        {" "}
        <b> HipHop.Dating</b> एक वैश्विक डेटिंग और <br /> सामाजिक मनोरंजन
        नेटवर्किंग प्लेटफॉर्म है
      </p>
      <p className="pre-1">
        {" "}
        अपने बारे में छोटे वीडियो पोस्ट करें! असली रहें, अपनी तारीख को आपको
        वैसेही स्वीकार करने दें,
        <br />
        अधिक अनुयायियों को आकर्षित करें, और अधिक पैसे कमाएं ।
      </p>
      <p className="pre-1-mobile-hi">
        {" "}
        अपने बारे में छोटे वीडियो पोस्ट करें! असली रहें, <br />
        पनी तारीख को आपको वैसेही स्वीकार करने दें,
        <br />
        अधिक अनुयायियों को आकर्षित करें, और अधिक पैसे कमाएं ।
      </p>

      <p className="pre-1">
        अपनी अनूठी प्रतिभाओ को प्रदर्शित करें, चाहेवह नृत्य और फैशन सेलेकर गायन,
        रैपिंग, या कॉमेडी तक हो, और
        <br />
        दुनिया का ध्यान आकर्षित करें। इस मंच का उपयोग करके चमकें और नए अवसरों के
        दरवाजे खोलें।
      </p>
      <p className="pre-1-mobile-hi">
        अपनी अनूठी प्रतिभाओ ंको प्रदर्शित करें, चाहेवह नृत्य और फैशन सेलेकर
        <br />
        गायन, रैपिंग, या कॉमेडी तक हो, और दुनिया का ध्यान आकर्षित करें।
        <br />
        इस मंच का उपयोग करके चमकें और नए अवसरों के दरवाजे खोलें।
      </p>
      <p className="pre-1">
        हाँ, <b>HipHop.Dating</b>, की विद्युतीय दुनिया मेंकदम रखें, सिंगल्स और{" "}
        <br />
        मज़े, कनेक्शन, और शोबिज़ की खोज कर रहेलोगों के लिए अंतिम गंतव्य! एक{" "}
        <br />
        <b>
          <i>
            जहां आप सिर्फ एक अन्य प्रोफ़ाइल नहीं हैं, बल्कि चमकने की प्रतीक्षा
            कर रहे एक सितारे हैं
          </i>
        </b>
        <br />
        बल्कि चमकनेके इं तजार मेंएक स्टार हैं। हमारेवीडियो फीचर के साथ अपनेसच
        <br />
        आत्म को प्रकट करें — असली आपको पेश करनेका एक अनूठा तरीका। साहसी बनें,
        सच्चेरहें,
        <br />
        और संभावित मैचों को आपके सच्चेव्यक्तित्व सेप्यार हो जाए।
      </p>
      <p className="pre-1">
        लेकिन इससेअधिक है! अपनी डेटिंग यात्रा को अपनी प्रतिभाओ ंके जश्न मेंबदल
        दें। चाहेआप <br />
        एक शानदार नर्तक, एक फै शन आइकन, एक मधुर गायक या रैपर हों, या लोगों को
        <br />
        हंसानेकी क्षमता रखतेहों, <b>HipHop.Dating</b> आपका मंच है। अपनेविशेष
        कौशल को प्रदर्शित करें, एक दर्शक वर्गको मोहित करें,
        <br />
        और अपनेअनुयायियों की संख्या को बढ़तेदेखें। और क्या? आपकी प्रतिभा के वल
        दिल नहीं जीतती; यह भी <br />
        लाभकारी रास्ता बन सकती है। संपर्क बनाएं, अनुयायी प्राप्त करें, और
        संभावित रूप से अपनी <br />
        कमाई बढ़ाएं, सभी डेटिंग और सामाजिक नेटवर्किं ग के रोमांच का आनंद
        लेतेहुए। <br />
      </p>
      <p className="pre-1">
        तैयार रहेंकि एक ऐसेसमुदाय का हिस्सा बनेंजहां उत्तेजना कभी खत्म नहीं होती
        और हर स्वाइप
        <br />
        एक अद्भुत नई कहानी की ओर लेजा सकती है। आज ही <b>HipHop.Dating</b>
        मेंशामिल हों और मजेदार, फ्लेयर,
        <br />
        और अद्भुत संभावनाओ ं सेभरेसफर की शुरुआत करें!
      </p>
      <p className="pre-1-mobile-hi">
        हाँ,<b>HipHop.Dating</b>, की विद्युतीय दुनिया मेंकदम रखें,
        <br />
        सिंगल्स और मज़े, कनेक्शन, और शोबिज़ की खोज कर <br />
        रहेलोगों के लिए अंतिम गंतव्य! एक <br />
        <b>
          <i>
            जहां आप सिर्फ एक अन्य प्रोफ़ाइल नहीं हैं, <br /> बल्कि चमकने की
            प्रतीक्षा कर रहे एक सितारे हैं
          </i>
        </b>
        . <br />
        बल्कि चमकनेके इं तजार मेंएक स्टार हैं। हमारेवीडियो फीचर के साथ अपनेसच
        आत्म को प्रकट करें — असली आपको पेश करनेका एक अनूठा तरीका। साहसी बनें,
        सच्चेरहें, और संभावित मैचों को आपके सच्चेव्यक्तित्व सेप्यार हो जाए।
      </p>
      <p className="pre-1-mobile-hi">
        लेकिन इससेअधिक है! अपनी डेटिंग यात्रा को अपनी प्रतिभाओ ंके जश्न मेंबदल
        दें। चाहेआप एक शानदार नर्तक, एक फै शन आइकन, एक मधुर गायक या रैपर हों, या
        लोगों को हंसानेकी क्षमता रखतेहों, <b>HipHop.Dating</b> आपका मंच है।
        अपनेविशेष कौशल को प्रदर्शित करें, एक दर्शक वर्गको मोहित करें, और
        अपनेअनुयायियों की संख्या को बढ़तेदेखें। और क्या? आपकी प्रतिभा के वल दिल
        नहीं जीतती; यह भी लाभकारी रास्ता बन सकती है। संपर्क बनाएं, अनुयायी
        प्राप्त करें, और संभावित रूप से अपनी कमाई बढ़ाएं, सभी डेटिंग और सामाजिक
        नेटवर्किं ग के रोमांच का आनंद लेतेहुए।
      </p>
      <p className="pre-1-mobile-hi">
        तैयार रहेंकि एक ऐसेसमुदाय का हिस्सा बनेंजहां उत्तेजना कभी खत्म नहीं होती
        और हर स्वाइप एक अद्भुत नई कहानी की ओर लेजा सकती है। आज ही{" "}
        <b>HipHop.Dating</b> मेंशामिल हों और मजेदार, फ्लेयर, और अद्भुत संभावनाओ
        ंसेभरेसफर की शुरुआत करें!
      </p>
    </div>
  );
};

export default Section2;
