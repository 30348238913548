import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/Hindi.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>यह असली लोगों के लिए है, कोई छल-कपट नहीं!</h3>
          <p class="mid_text_p">
            अपनेबारेमेंछोटेवीडियो पोस्ट करें, अपनेबारेमें, अपनेविजन, शौक, आदि के
            बारेमें बात करें।
            <br /> अपनेआप को प्रदर्शित करें, अपनी प्रतिभाओ ंको दिखाएँऔर अपनेडेट
            और फॉलोअर्स को आकर्षित करें।
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            अपनेबारेमेंछोटेवीडियो पोस्ट करें, अपनेबारेमें, अपनेविजन, शौक, आदि के
            बारेमें बात करें। अपनेआप को प्रदर्शित करें, अपनी प्रतिभाओ ंको
            दिखाएँऔर अपनेडेट और फॉलोअर्स को आकर्षित करें।
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
