import React from "react";
import "./sec4.css";
import Sec4img from "../../../../Assets/why_different_img.png";
const Section4 = () => {
  return (
    <div class="container3">
      <div class="conten">
        <div class="cont3-h1">
          <h1>لاذا وكيف نحن مختلفون؟</h1>
        </div>
        <div class="main-conten">
          <div class="left-side-text_AR">
            <p>
              ميزة HipHop.Dating في المقارنة
              <br />
              مع العديد من منصات المواعدة الأخرى هو هذا كل شيء
              <br />
              لدى المستخدمين بالفعل شغف رئيسي مشترك في
              <br />
              الحياة التي هي "حب الهيب هوب وثقافته".
              <br />
              <br />
              تسمح الأنظمة الأساسية الأخرى للمستخدمين بإجراء <br />
              فحص مسبق لإمكانات المباريات التي يرونها، وتصفيتها
              <br />
              الأجناس أو الأعمار أو المواقع غير المرغوب فيها. أنت تقوم بالتصفية
              <br />
              أنت تشير إلى "مهتم/غير مهتم"، تكرر ذلك، وما إلى ذلك.
              <br /> بعض التطبيقات كذلك باستخدام الخوارزميات التي تختار
              <br /> من سيظهر لك ومتى.
            </p>
          </div>
          <div class="mid-side-img">
            <img src={Sec4img} alt="" />
          </div>
          <div class="right-side-text_AR">
            <p>
              عدم وجود المصلحة المشتركة الأساسية والعاطفة <br /> والحب بين
              المستخدمين يجعل من الصعب ل
              <br />
              العديد من تطبيقات المواعدة لمساعدة المستخدمين <br /> بشكل واقعي في
              العثور عليها رفقاء الروح.
              <br />
              <br />
              في <b>HipHop.Dating</b>، يعرف المستخدمون
              <br /> بالفعل أنهم نشترك في نفس الاهتمام والعاطفة والحب
              <br />
              الهيب هوب وثقافته (نمط الحياة، الموسيقى، الموضة،
              <br />
              الرقص، الشعر، الفنون، الخ). يمكنهم ببساطة الدردشة،
              <br />
              التاريخ والمشاركة واكتشاف بعضنا البعض بشكل طبيعي.
              <br />
              <br />
              ذلك، يمكن لـ <b>Hip Hop Daters</b> (المستخدمون)
              <br />
              إنشاء بالإضافة إلى المال عن طريق إنشاء ونشر <br /> المحتويات أثناء
              الاستمتاع.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
