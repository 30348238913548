import React from "react";
import "./sec5.css";
import Sec5_img from "../../../../Assets/German.png";
const Section5 = () => {
  return (
    <div class="container6">
      <div class="six-sec">
        <div class="mid-text">
          <h1>HipHop.Dating</h1>
          <h3>ist für echte Menschen, keine Tricks!</h3>
          <p class="mid_text_p">
            Poste kurze Videos über dich, erzähle von dir, deinen Visionen,
            deinen Hobbys usw. <br /> Präsentiere dich, deine Talente und ziehe
            dein Date und deine Follower an.
          </p>
          <p class="mid_text_p_mobile">
            {" "}
            Poste kurze Videos über dich, erzähle von dir, deinen Visionen,
            deinen Hobbys usw. Präsentiere dich, deine Talente und ziehe dein
            Date und deine Follower an.
          </p>
        </div>
        <div class="bottom-img">
          <img src={Sec5_img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section5;
